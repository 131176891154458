
 <template> 
   <div> 
        <section class="blog">
            <div class="container">
                <div class="row">

                    <!-- Blog Content -->
                    <div class="col-md-9">

                        <div class="blog-post">
                            <div class="mt20 mb20">
                                <img src="img/services/Group 2107.jpg" class="img-responsive width100"
                                alt="#">
                            </div>

                            <div class="post-header">
                                <a class="link-to-post" href="#"><h3><strong>System Intergration</strong></h3></a>
                            </div>
                            <p>Your enterprise ecosystem depends highly on the functioning of your applications and their subsequent interaction with other legacy applications. 
                                Your company needs an agile environment for seamless sharing of data and information through applications for business operations to run smoothly.
                                 This can be achieved only when your enterprise applications are efficiently integrated and perform at par with the required standards.
                                  The growing IT ecosystem complexities within your enterprise, complicated hybrid approaches,
                                   availability of a multitude of cloud system versions, high costs of application management,
                                    etc., are roadblocks in the effective working of your enterprise application integration model. 
                                    These problems can be resolved when you have an expert managing it all for you.
                                </p>
                                    <blockquote>Why choose Magilatech to Outsource Application Integration Services?</blockquote>
                                    
                                    <p>Magilatech has a rich experience of over 8 years in the field of application development, management,
                                     and integration, making us one of the most reputed enterprise application 
                                     integration services providers in the world with strong expertise in offering 
                                     end-to-end integration of heterogeneous applications and business processes through effective
                                      unification across the enterprise. Our scalable and extensive enterprise application integration
                                       solutions facilitate reduced operational cost and enhanced ROI for the businesses along with a smooth-functioning IT environment.
                                    </p>
                                    
                                    <blockquote>Outsource Application Integration Services from Magilatech</blockquote>
                                    <p>Let Magilatech unleash the true potential of your enterprise and lead a successful application
                                        transformation for reduced costs of operations and greater returns on your company's investments.
                                        With an innovative and bold approach towards business application integration, our team of expert designers and developers offer
                                        leading-edge ideas and solutions to scale-up your business performance through streamlined and integrated internal applications, 
                                        databases, and processes. Schedule an appointment with one of our experts today and get more information
                                        on our specialized enterprise application integration services.
                                 </p>
                        </div>

                    </div>
                    <!-- End Blog Content -->

                    <!-- Sidebar -->
                    <div class="col-md-3 sidebar">

                        <div class="blog-widget">
                            <h5>About</h5>
                            <p>In 2012, Magilatech was founded with a clear mission to transform software development and cybersecurity solutions in Africa and beyond. Since its inception, Magilatech has grown from a startup incubatee, to a high growth technology company serving clients across the globe.</p>
                        </div>

                        <div class="blog-widget">
                            <h5>Quick Links</h5>
                            <ul class="category-list list-icons">
                                <li v-for="(service, index) in services" :key="index">
                                    <a :href="service.link"><i class="ion-ios-arrow-right"></i>{{ service.title }}</a>
                                </li>                            
                            </ul>
                        </div>

                    </div>
                    <!-- End Sidebar -->
                </div>
            </div>
        </section>
      <Footer/>      
    </div>
</template>
<script>
import Footer from '@/views/home/footer.vue'
import serviceUtil from '@/helpers/serviceUtil.js'
export default {
  components: {
    Footer
  },
  computed: {
    services () {
      return serviceUtil.services()
    }
  },
}
</script>