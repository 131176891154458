
 <template> 
   <div> 
        <section class="blog">
            <div class="container">
                <div class="row">

                    <!-- Blog Content -->
                    <div class="col-md-9">

                        <div class="blog-post">
                            <div class="mt20 mb20">
                                <img src="img/services/Group 2099.jpg" class="img-responsive width100"
                                alt="#">
                            </div>

                            <div class="post-header">
                                <a class="link-to-post" href="#"><h3><strong>Penetration Testing</strong></h3></a>
                            </div>
                           



                            <p>A penetration test is a simulation of a hacker attack on a network, system, application or website used to discover existing weaknesses before hackers find and exploit them.We determine feasibility of an attack and the amount of business impact of a successful network compromise. We test and report all potential vulnerabilities both externally and internally that result from improper system configuration, hardware or software flaws and missing patches.</p>
                            <p>
                            Any security issues that are found will be presented to the system owner together with an assessment of their impact and often with a proposal for mitigation or a technical solution.</p>
                            <p>
                           Our network security assessment team’s approach addresses wide-ranging needs for our clients and we offer an in-depth and comprehensive penetration testing service that audit systems from an external or internal perspective.</p>
                               <p>
                            External penetration testing - usually takes place across the Internet to simulate the view of your systems through the eyes of a potential attacker.
                             </p>
                                <p>
                                Internal penetration testing - enables a business to comprehensively evaluate how easily internal employees are able to access sensitive information.
                                </p>

                                <blockquote>What We Test at Magilatech</blockquote>

                                <p>Operating systems (Windows, Linux, Unix and Mac), applications, networking equipment.</p>
                                 <p>  Wireless (WIFI, Bluetooth etc.)</p>
                                    <p>Personnel (screening process, social engineering etc.)</p>
                                      <p>Physical (access controls, dumpster diving etc.).</p>
                                       <p>Magilatech experts are certified from a wide range including CISA, CEH...</p>


                                       <blockquote>What you gain after Network Security Assessment / Penetration test</blockquote>

                                 <p> Preventing financial loss through fraud (hackers, extortionists and disgruntled employees)</p>
                                    <p>Protecting your brand by avoiding loss of consumer confidence and business reputation.</p>
                                      <p>Physical (access controls, dumpster diving etc.).</p>
                                       <p>Proving due diligence and compliance to your industry regulators, customers and shareholders.</p>
                                         
                                    <p>Non-compliance can result in your organization losing business, receiving heavy fines, gathering bad PR or ultimately failing.</p>
                                       

                                    <!--<blockquote>Why choose Magilatech to Outsource Application Integration Services?</blockquote>
                                    <p>Magilatech has a rich experience of over 10 years in the field of application development, management,
                                     and integration, making us one of the most reputed enterprise application 
                                     integration services providers in the world with strong expertise in offering 
                                     end-to-end integration of heterogeneous applications and business processes through effective
                                      unification across the enterprise. Our scalable and extensive enterprise application integration
                                       solutions facilitate reduced operational cost and enhanced ROI for the businesses along with a smooth-functioning IT environment.
                                    </p>
                                    
                                    <blockquote>Outsource Application Integration Services from Magilatech</blockquote>
                                    <p>Let Magilatech Solutions unleash the true potential of your enterprise and lead a successful application
                                        transformation for reduced costs of operations and greater returns on your company's investments.
                                        With an innovative and bold approach towards business application integration, our team of expert designers and developers offer
                                        leading-edge ideas and solutions to scale-up your business performance through streamlined and integrated internal applications, 
                                        databases, and processes. Schedule an appointment with one of our experts today and get more information
                                        on our specialized enterprise application integration services.
                                 </p>-->
                        </div>

                    </div>
                    <!-- End Blog Content -->

                    <!-- Sidebar -->
                    <div class="col-md-3 sidebar">

                        <div class="blog-widget">
                            <h5>About</h5>
                            <p>In 2012, Magilatech was founded with a clear mission to transform software development and cybersecurity solutions in Africa and beyond. Since its inception, Magilatech has grown from a startup incubatee, to a high growth technology company serving clients across the globe.</p>
                        </div>

                        <div class="blog-widget">
                            <h5>Quick Links</h5>
                            <ul class="category-list list-icons">
                                <li v-for="(service, index) in services" :key="index">
                                    <a :href="service.link"><i class="ion-ios-arrow-right"></i>{{ service.title }}</a>
                                </li>                            
                            </ul>
                        </div>

                    </div>
                    <!-- End Sidebar -->
                </div>
            </div>
        </section>
      <Footer/>      
    </div>
</template>
<script>
import Footer from '@/views/home/footer.vue'
import serviceUtil from '@/helpers/serviceUtil.js'
export default {
  components: {
    Footer
  },
 computed: {
    services () {
      return serviceUtil.services()
    }
  },
}
</script>