<template> 
   <div> 
        <section class="blog">
            <div class="container">
                <div class="row">
                    <!-- Blog Content -->
                    <div class="col-md-9">

                        <div class="blog-post">
                            <div class="mt20 mb20">
                                <img src="img/services/Group 2105.jpg" class="img-responsive width100"
                                alt="#">
                            </div>

                            <div class="post-header">
                                <a class="link-to-post" href="#"><h3><strong>Embedded Systems</strong></h3></a>
                            </div>
                            <p>Are you looking for embedded software development solutions for things like industrial and home automation applications, embedded silicon, consumer electronics products, and GPS systems? Do you need a team to handle all or part of your architecting, prototyping, programming, and testing of your embedded software? If so, you have come to the right place.</p>
                              
                               <p>
                            Magilatech  is a leading embedded software development service provider and can help you with any of your embedded software development requirements. We have experience working with independent software vendors, semiconductor manufacturers, and manufacturers of embedded components, devices, and equipment to enhance the research and development cycle and drive new product development. We specialize in firmware and embedded software design. We have provided solutions for industrial and home automation, IoT, Telematics, M2M (Machine to Machine) solutions, device drivers, automotive embedded systems, Bluetooth, security solutions, and many more.
                            </p>
                             <blockquote>Our Embedded Software Development Services</blockquote>
                        

                              <p><b>Embedded Software Programming Services</b>
                              Our company and embedded software experts provide end-to-end programming services for many different kinds of device drivers, microcontrollers and microprocessors, IoT and M2M devices, human interface devices, and many more. We also help with data exchange between embedded software and external systems.
                                </p>


                                 <p><b>Embedded Technology Proficiency</b>
                             Our Magilatech expert developers have expertise in all things related to embedded software, including high proficiency in languages such as embedded C and C++, Java, Python etc. We work with most of the popular architectures, such as ARM, 8051, X86-64, and also have a deep understanding of the common embedded protocols, standards, and interfaces.
                                </p>

                               <p><b>Hardware Programming Expertise</b>
                              We program a range of hardware, right from rudimentary devices to more complex industrial equipment. We have worked with network equipment, medical equipment, laboratory equipment, digital signage, and POS terminals, among others. We also develop mobile apps to control various kinds of hardware.
                                </p>


                                 <p><b>Embedded Linux Proficiency</b>
                              We are well versed with the Linux kernel and tech stack and have harnessed its power to program many different advanced systems related to industrial automation, consumer electronics, and IoT and M2M solutions.
                                </p>

                               <blockquote>Outsource Embedded Software Development Services to Magilatech</blockquote>

                               <p>
                              Magilatech provides highly customized and high-quality embedded software development services to clients from all over the world. Our team uses the latest and best-embedded software tools and technologies to always provide cutting-edge services. Our dedication to quality and accuracy have also ensured that clients keep coming back to us for more.

                              If you're looking for high-quality and cost-effective embedded software development services, get in touch with us today!
                                </p>

                             </div>

                            </div>
                    <!-- End Blog Content -->

                    <!-- Sidebar -->
                    <div class="col-md-3 sidebar">
                        <div class="blog-widget">
                            <h5>About</h5> 
                            <p>In 2012, Magilatech was founded with a clear mission to transform software development and cybersecurity solutions in Africa and beyond. Since its inception, Magilatech has grown from a startup incubatee, to a high growth technology company serving clients across the globe.</p>
                        </div>

                        <div class="blog-widget">
                            <h5>Quick Links</h5>
                            <ul class="category-list list-icons">
                                <li v-for="(service, index) in services" :key="index">
                                    <a :href="service.link"><i class="ion-ios-arrow-right"></i>{{ service.title }}</a>
                                </li>                            
                            </ul>
                        </div>

                    </div>
                    <!-- End Sidebar -->
                </div>
            </div>
        </section>
      <Footer/>      
    </div>
</template>
<script>
import Footer from '@/views/home/footer.vue'
import serviceUtil from '@/helpers/serviceUtil.js'
export default {
  components: {
    Footer
  },
  computed: {
    services () {
      return serviceUtil.services()
    }
  },
}
</script>