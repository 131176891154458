
 <template> 
   <div> 
        <section class="blog">
            <div class="container">
                <div class="row">

                    <!-- Blog Content -->
                    <div class="col-md-9">

                        <div class="blog-post">
                            <div class="mt20 mb20">
                                <img src="img/assets/vr-exp.jpg" class="img-responsive width100"
                                alt="#">
                            </div>

                            <div class="post-header">
                                <a class="link-to-post" href="#"><h3><strong>Virtual & Augmented Reality</strong></h3></a>
                            </div>

           
                           <p>
                          In Magilatech we create Virtual & Augmented Reality solutions that are adaptable and relevant to market with a global appeal. We connect consumers, businesses and organizations to rich, immersive VR experiences.
                            </p>
                           <p> 
                               
                          We have established the language and conventions of a new form of creating compelling content that is engaging and Immersive.
                           </p>

                            <p>
                              Story telling that connects with people and pushes the bounds of empathy. Our cultural stories are meant to amplify the voices of those who are often unheard.
                           </p>

                           <blockquote>Our Magilatech Virtual Reality Services</blockquote>

                           <p><b>
                            Educative & Entertaining
                           </b>We believe that knowledge is a human right and Immersive virtual reality technology has the power to educate and capture the imagination of children and students. We use this technology to create exciting edutainment experiences by awakening the curiousity for learning in students through science, sports, music, or other fun experiences.
                            </p>

                            <p><b>
                            Experiential
                           </b>Every event needs a memorable and sharable moment! Experiencing Virtual Reality for the first time is something an audience will never forget. We deliver the newest and most powerful experiential technology at your next corporate or private event. We use virtual reality and marry it with digital technology to bring key brand messages to life in a tangible, sensory, virtual world.
                            </p>


                            <p><b>
                           Property
                           </b>Immersive technology is set to emerge as a powerful tool in selling property developments both off-the-plan and finished. Through the power of VR & AR, we will allow prospective buyers to walk through and experience the space even before construction has started in a tangible and real way or after the development is completed as an open house site visit from the comfort of your office or home.
                            </p>

                          <p><b>Doing Marketing and Advertising
                           </b>Traditional methods of consumer engagements are no long as effective. The modern day consumers are using and enjoying technology, and adopting different ad formats that reflect and complement their behavior and subsequently brands are increasingly looking for interactive and immersive ad formats. Marketers must capitalize on this in a responsible, brand appropriate way that provides a highly individual and emotional experience for their consumers.
                          </p>
            

                            <blockquote>Outsource Virtual & Augmented Reality Services from Magilatech</blockquote>
                            <p>Let Magilatech unleash the true potential of your company and lead a successful application transformation for reduced costs of operations and greater returns on your company's investments. With an innovative and bold approach towards  To design, democratize, demystify and deploy VR and new emerging technologies, change the visual narrative of how stories are told in Africa, equip the African youth to be the VR Innovators, and Inventors and storytellers of the future. business application integration, our team of expert is ready to give all services. Schedule an appointment with one of our experts today and get more information  on our Virtual & Augmented Reality.  
                            </p>
                        </div>
                    </div>
                    <!-- End Blog Content -->

                    <!-- Sidebar -->
                    <div class="col-md-3 sidebar">
                        <div class="blog-widget">
                            <h5>About</h5>
                            <p>In 2012, Magilatech was founded with a clear mission to transform software development and cybersecurity solutions in Africa and beyond. Since its inception, Magilatech has grown from a startup incubatee, to a high growth technology company serving clients across the globe.</p>
                        </div>

                        <div class="blog-widget">
                            <h5>Quick Links</h5>
                            <ul class="category-list list-icons">
                                <li v-for="(service, index) in services" :key="index">
                                    <a :href="service.link"><i class="ion-ios-arrow-right"></i>{{ service.title }}</a>
                                </li>                            
                            </ul>
                        </div>
                    </div>
                    <!-- End Sidebar -->
                </div>
            </div>
        </section>
      <Footer/>      
    </div>
</template>
<script>
import Footer from '@/views/home/footer.vue'
import serviceUtil from '@/helpers/serviceUtil.js'
export default {
  components: {
    Footer
  },
 computed: {
    services () {
      return serviceUtil.services()
    }
  },
}
</script>