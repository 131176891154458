var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"blog"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-9"},[_c('ul',{staticClass:"blog-list"},_vm._l((_vm.posts),function(post,index){return _c('li',{key:index,staticClass:"row vertical-align"},[_c('div',{staticClass:"col-md-6 mt20 mb20"},[_c('router-link',{attrs:{"to":{ 
                                            name: 'blog-details',
                                            params: { id: post.id }
                                        }}},[_c('img',{staticClass:"img-responsive width100",attrs:{"src":_vm.uploadUrl+post._embedded['wp:featuredmedia'][0].media_details.file,"alt":"#"}})])],1),_c('div',{staticClass:"col-md-6"},[_c('div',{},[_c('h5',[_c('span',[_vm._v("In")]),_c('strong',[_c('router-link',{staticClass:"color",attrs:{"to":{ 
                                            name: 'blog-details',
                                            params: { id: post.id }
                                        }}},[_vm._v(" "+_vm._s(post._embedded['wp:term'][0][0].name))])],1)]),_c('router-link',{attrs:{"to":{ 
                                            name: 'blog-details',
                                            params: { id: post.id }
                                        }}},[_c('h3',[_c('strong',[_vm._v(_vm._s(post.title.rendered))])])])],1),_c('div',{domProps:{"innerHTML":_vm._s(_vm.formatPost(post.content.rendered))}}),_c('div',{staticClass:"post-header"},[_c('h5',[_c('strong',[_c('router-link',{staticClass:"color",attrs:{"to":{ 
                                            name: 'blog-details',
                                            params: { id: post.id }
                                        }}},[_vm._v("Read more")])],1)])])])])}),0)]),(_vm.posts)?_c('div',{staticClass:"col-md-3 sidebar"},[(_vm.posts)?_c('div',{staticClass:"blog-widget"},[_c('h5',[_vm._v("About")]),_c('p',[_vm._v("Magilatech is a software development and cyber security company with headquartered in Tanzania. Founded in 2012 we have been passionately helping companies of all sizes across the globe to accomplish their digital transformation..")])]):_vm._e(),(_vm.posts)?_c('div',{staticClass:"blog-widget"},[_c('h5',[_vm._v("Categories")]),_c('ul',{staticClass:"category-list list-icons"},_vm._l((_vm.categories),function(category,index){return _c('li',{key:index},[_c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"ion-ios-arrow-right"}),_vm._v(" "+_vm._s(category.name)+" ")])])}),0)]):_vm._e(),(_vm.posts)?_c('div',{staticClass:"blog-widget blog-tags"},[_c('h5',[_vm._v("Tags")]),_c('ul',{staticClass:"tags-list"},_vm._l((_vm.tags),function(tag,index){return _c('li',{key:index},[_c('a',{attrs:{"href":"#"}},[_vm._v(_vm._s(tag.name))])])}),0)]):_vm._e()]):_vm._e()])])]),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }