
 <template> 
   <div> 
        <section class="blog">
            <div class="container">
                <div class="row">

                    <!-- Blog Content -->
                    <div class="col-md-9">

                        <div class="blog-post">
                            <div class="mt20 mb20">
                                <img src="img/services/Group 2098.jpg" class="img-responsive width100"
                                alt="#">
                            </div>

                            <div class="post-header">
                                <a class="link-to-post" href="#"><h3><strong>Annual Maintainance Support</strong></h3></a>
                            </div>

                            <p>
                            A common perception of maintenance is that it merely involves fixing defects. However, one study indicated that over 80% of maintenance effort is used for non-corrective actions. This perception is perpetuated by users submitting problem reports that in reality are functionality enhancements to the system. More recent studies put the bug-fixing proportion closer to 21%.
                          </p>

                          <blockquote>Magilatech offer an Annual Maintainance Support Services</blockquote>
                            
                            <p>
                             <b>Incident management</b> 
                             Reduce outages, improve agent productivity, and manage the complete life cycle of services rendered.
                            </p>
<br>
                             <p>
                             <b>Asset management</b> 
                            Optimize asset utilization, avoid vulnerabilities, and ensure license compliance.
                            </p>
     <br>                       

                            <p>
                             <b>Project management</b> 
                             Create projects, manage resources, track progress, and integrate projects with requests and changes to fine-tune your overall IT service delivery.
                            </p>
                            

                               <blockquote>In Magilatech We provide Annual Maintainance Support</blockquote>
                            <p>
                             Annual Maintainance Support is a very broad activity that includes error correction, enhancements of capabilities, deletion of obsolete capabilities, and optimization. Because change is inevitable, mechanisms must be developed for evaluation, controlling and making modifications with our innovation skills from heart in Magilatech.</p>
                             <p> 
                             So, Any work done to maintain the defects is considered to be maintenance work. The purpose is to preserve the value. The value can be enhanced by expanding the customer base, meeting additional requirements, efficient and employing newer technology. Maintenance may span for 20 years. We assure you with good services please feel at peace and choose us to work with you!

                            </p>


                         
                        </div>

                    </div>
                    <!-- End Blog Content -->

                    <!-- Sidebar -->
                    <div class="col-md-3 sidebar">

                        <div class="blog-widget">
                            <h5>About</h5>
                            <p>In 2012, Magilatech was founded with a clear mission to transform software development and cybersecurity solutions in Africa and beyond. Since its inception, Magilatech has grown from a startup incubatee, to a high growth technology company serving clients across the globe.</p>
                        </div>

                        <div class="blog-widget">
                            <h5>Quick Links</h5>
                            <ul class="category-list list-icons">
                                <li v-for="(service, index) in services" :key="index">
                                    <a :href="service.link"><i class="ion-ios-arrow-right"></i>{{ service.title }}</a>
                                </li>                            
                            </ul>
                        </div>

                    </div>
                    <!-- End Sidebar -->
                </div>
            </div>
        </section>
      <Footer/>      
    </div>
</template>
<script>
import Footer from '@/views/home/footer.vue'
import serviceUtil from '@/helpers/serviceUtil.js'
export default {
  components: {
    Footer
  },
  computed: {
    services () {
      return serviceUtil.services()
    }
  }, 
}
</script>