
 <template> 
   <div> 
        <section class="blog">
            <div class="container">
                <div class="row">

                    <!-- Blog Content -->
                    <div class="col-md-9">

                        <div class="blog-post">
                            <div class="mt20 mb20">
                                <img src="img/services/Group 2114.jpg" class="img-responsive width100"
                                alt="#">
                            </div>

                            <div class="post-header">
                                <a class="link-to-post" href="#"><h3><strong>Incidents Mananagement</strong></h3></a>
                            </div>


                            <p>We have set of procedures and actions taken to respond to and resolve critical incidents, as we identify, analyze, and correct hazards to prevent a future re-occurrence. Therefore the process of limiting the potential disruption caused by such an event, followed by a return to business as usual is what we do as we manage Incidents in Magilatech.</p>



                            <blockquote>Magilatech Incident Management supports the incident management process in the following ways</blockquote>

                            <p>1. Log incidents in the instance or by sending email.</p>
                            <p>2. Classify incidents by impact and urgency to prioritize work.</p>
                            <p>3. Assign to appropriate groups for quick resolution.</p>
                            <p>4. Escalate as necessary for further investigation.</p>
                            <p>5. Resolve the incident and notify the user who logged it.</p>
                            <p>6. Use reports to monitor, track, and analyze service levels and improvement. </p>


                          <blockquote>Benefits of Incidents Management</blockquote>


                             <p><b>  
                               Restore services—automatically and fast</b>
                             
                            Bring together the right agents to manage work and collaborate using one platform for IT processes.
                            </p>

                             <br>
                             <p><b>
                                Ignite agent productivity</b>
                             
                            Assign incidents to the right groups for faster resolution with the help of machine learning</p>
                             <br>                           

                             <p><b>
                           Boost employee productivity</b>
                               
                            Deliver a better experience with intuitive omni-channel self-service and two-way communication.


                            </p>
                            

                            <br>
                             <p><b>
                              Increase incident deflection</b>
                              Incident deflection can come from providing an FAQ to our customers to read, a product forum for them to participate in, or just a solid, thorough library of information related to your products and services, which is available to your customers. 
                            
                              </p>

                              <blockquote>Features of Incident Management</blockquote>

                              <p><b>Single-pane agent view </b>
                                Give agents what they need in one place. Prioritize and resolve issues fast with AI recommendations.
                              </p>

                              <p><b>Native mobile app</b>
                                Give IT agents a mobile interface to triage, address, and resolve incidents or requests on the go.


                              </p>

                              <p><b>AI-powered insight </b>
                                Accelerate incident resolution with built-in machine learning and contextual help to eliminate bottlenecks.
                              </p>

                              <p><b>Major incident management </b>
                               Use embedded, proven practice workflows to identify, track, and resolve high‑impact incidents.
                              </p>
                             
                             <blockquote>Outsource Incidents Management services from Magilatech</blockquote>

                            
                            <p>Let Magilatech unleash the true potential of your company and lead a successful incident management for reduced costs of operations and greater returns on your company's investments but also, Incident Management restores normal service operation while minimizing impact to business operations and maintaining quality. Schedule an appointment with one of our Magilatech experts today and get more information  on our Incidents Management.
                             
   
                            </p>
                          
                            
                        </div>

                    </div>
                    <!-- End Blog Content -->

                    <!-- Sidebar -->
                    <div class="col-md-3 sidebar">

                        <div class="blog-widget">
                            <h5>About</h5>
                            <p>In 2012, Magilatech was founded with a clear mission to transform software development and cybersecurity solutions in Africa and beyond. Since its inception, Magilatech has grown from a startup incubatee, to a high growth technology company serving clients across the globe.</p>
                        </div>

                        <div class="blog-widget">
                            <h5>Quick Links</h5>
                            <ul class="category-list list-icons">
                                <li v-for="(service, index) in services" :key="index">
                                    <a :href="service.link"><i class="ion-ios-arrow-right"></i>{{ service.title }}</a>
                                </li>                            
                            </ul>
                        </div>

                    </div>
                    <!-- End Sidebar -->
                </div>
            </div>
        </section>
      <Footer/>      
    </div>
</template>
<script>
import Footer from '@/views/home/footer.vue'
import serviceUtil from '@/helpers/serviceUtil.js'
export default {
  components: {
    Footer
  },
  computed: {
    services () {
      return serviceUtil.services()
    }
  },
}
</script>