<template>
    <div>
        <section id="hero" class="hero-fullwidth parallax" data-overlay-dark="6">
            <div class="background-image">
              <vue-particles
                class="particles-js"
                color="#ffffff"
                :particleOpacity="0.7"
                linesColor="#ffffff"
                :particlesNumber="100"
                shapeType="circle"
                :particleSize="5"
                :linesWidth="2"
                :lineLinked="true"
                :lineOpacity="0.4"
                :linesDistance="150"
                :moveSpeed="6"
                :hoverEffect="true"
                hoverMode="grab"
                :clickEffect="true"
                clickMode="push"
              >
              </vue-particles>               
            </div>

            <div class="container">
                <div class="row">

                    <div class="col-md-12 text-center">
                        <h1>Our <strong>Products  </strong></h1>
                        <p class="lead">We are tech company offering a variety of solutions in software development and cyber security.</p>
                    </div>

                </div>
            </div>
        </section>
        <!-- End Hero -->



        <!-- Blog -->
        <section class="pt110 pb90 bg-grey">
            <div class="container">
                <div class="row text-center">

                  <div class="col-md-12 text-center pb20">
                      <h3><strong>OUR DIGITAL SOLUTIONS</strong></h3>
                      <p class="lead">At Magilatech, we strive to deliver more than what is typically offered.  
It means more than just solutions we design and build. We are also connected to our customers and invested in their success. We understand that an enterprise can not afford to build a solution that only works today but must scale for tomorrow. 
</p>
                  </div>


                    <div class="blog-grid">
                        <!-- Blog Items Container-->
                        <div class="vossen-blog-grid">
                            <!-- Blog Item -->
                            <div v-for="(service, index) in services" :key="index" class="col-md-4 col-sm-6">
                                <div class="blog-grid-img">
                                    <a :href="service.link"><img :src="service.imgUrl" class="img-responsive width100" alt="#"></a>
                                </div>
                                <div class="blog-grid-content">
                                    <div class="post-header">
                                    </div>
                                    <p>{{trimText(service.description)}}</p>
                                    <div class="post-header">
                                        <h5>
                                            <strong>
                                            <a :href="service.link" class="color">Read more</a>
                                           </strong>
                                       </h5>
                                    </div>                                     
                                </div>                                
                            </div>
                            <!-- Blog Item -->
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Blog -->

      <Footer/>      
    </div>
</template>
<script>
import Footer from './home/footer.vue'
export default {
  components: {
    Footer
  },
  data() {
    return {
      services: [
        {
          type: 'Web Appliaction',
          title: 'BESP', 
          description: 'An offline and online digital learning platform that enables entrepreneurs to acquire business management skills and enable them to be innovative in optimizing opportunities provided by digital economy in wealth maximization and jobs creation. The idea was initially proposed to inspire and promote technology based young start up entrepreneurs in Tanzania.',
          imgUrl: 'img/portfolio/besp.png',
          link: '/besp',          
        },
        {
          type: 'Web Appliaction',
          title: 'Research Management System', 
          description: 'A web based platform through which information about research projects and researchers is managed. This is a project that we implemented for the Tanzania Commission for Science and Technology (COSTECH).',
          imgUrl: 'img/portfolio/crweb.png',
          link: '/rms',          
        },
        {
          type: 'Enterprise Appliaction',
          title: 'PACT - MyWorth Women Savings', 
          description: 'A saving groups application that aims to support communities from poor countries to become financially literate and promote women empowerment. The application includes a mobile e-ledger and simplified accounting system that provides users access to their records in real time, reduces transaction time and the duration of group meetings, and improves the quality of group records.',
          imgUrl: 'img/portfolio/myworth.png',
          link: '/myworth',          
        },
        {
          type: 'Enterprise Appliaction',
          title: 'COSTECH Repository', 
          description: 'A web based platform we implemented for the Tanzania Commission for Science and Technology (COSTECH); whose main objective was to provide means to interlink the COSTECH IR with other institutional data systems at 15 R&D institutions. It acts as an archive for collecting, preserving, and disseminating digital copies of the intellectual output of an institution, particularly a research or academic institutions.',
          imgUrl: 'img/portfolio/cir.png',
          link: '/repository',          
        },
        {
          type: 'Mobile Appliaction',
          title: 'Tigo Backup', 
          description: 'A cloud storage and Anti-theft security application for Tigo customers. It is useful for anyone who needs an easy way to store, sync, stream, and restore data. With TigoBackup you can keep and save your contacts, photos, music and videos from your device to TigoBackup secured storage TigoBackup is very easy to use and has all important features that you need to secure your phone and data. You can initiate by SMS to alert and find stolen phone. Also with the online storage you can access your data anytime on the App and at www.tigobackup.com.',
          imgUrl: 'img/portfolio/tigo_backup.png',
          link: '/tigo-backup',          
        },
        {
          type: 'Mobile Appliaction',
          title: 'AIS', 
          description: 'Backs up your contacts, photos and videos from your device on to secure cloud storage. What differentiate AIS Cloud+ from others are its design, ease of usage and many additional features that help you organize data, search photos, tag photos with objects, scenes, places and time, share files using AIS Cloud+ share link, etc. Secondly, you get a huge cloud storage and you can view your data anytime at https://www.aiscloudplus.com',
          imgUrl: 'img/portfolio/ais.png',
          link: '/ais',          
        },
        {
          type: 'Mobile Appliaction',
          title: 'VSOMO (Airtel-Veta)', 
          description: 'A platform that helps students to take their VETA short courses through their mobile phones. This is a live and ongoing project we delivered for the Vocational Education and Training Authority (VETA) in collaboration with Airtel Tanzania. The Airtel VSOMO app is used primarily for learning vocational technical skills that has been constructed in a way that creates real value for program organizers, participants and other stakeholders. VETA has 29 centers offering long and short-term courses. Every year 15,000 students apply but VETA can only accommodate 4,000 students. Airtel and VETA in collaboration with Magilatech company decided to establish an app-based digitized learning and training platform, VSOMO.',
          imgUrl: 'img/portfolio//vsomo.jpg',
          link: '/visomo',          
        },
        // {
        //   type: 'Enterprise Appliaction',
        //   title: '(PRMS) Property Rate Management System', 
        //   description: 'A platform that allows for the general public to map property (buildings) and make payments to TRA. PRMS has been successfully been integrated with the Tanzania Government e-Payment Gateway (GEPG). This project was a subcontract from Dayone Softcom Company.',
        //   imgUrl: 'img/portfolio/prms.png',
        //   link: '/prms',          
        // },
        {
          type: 'Desktop Appliaction',
          title: 'Besp Desktop', 
          description: 'An offline and online digital learning platform that enables entrepreneurs to acquire business management skills and enable them to be innovative in optimizing opportunities provided by digital economy in wealth maximization and jobs creation. The idea was initially proposed to inspire and promote technology based young start up entrepreneurs in Tanzania.',
          imgUrl: 'img/portfolio/besp_desktop.png',
          link: '/besp',          
        },
        {
          type: 'Enterprise Appliaction',
          title: '(LGRCIS) Local government revenue collection', 
          description: 'Local Government Revenue Information System that facilitate revenue collection for local government authorities. Local Government Revenue Information System (LGRCIS), the system for revenue collection on all Local Government Authorities, and it has successfully been integrated with the Tanzania Government e-Payment Gateway (GEPG). This project was a subcontract from Dayone Softcom Company.',
          imgUrl: 'img/portfolio/revenue.png',
          link: '/lgrcis',          
        },
        {
          type: 'Enterprise Appliaction',
          title: 'Automated Pensional Verification System', 
          description: 'A platform that validate pensioners for payment. The platform uses biometric inputs to validate pensioners and automate payments upon validation.',
          imgUrl: 'img/portfolio/pspf.png',
          link: '/pspf',          
        },
        {
          type: 'Enterprise Appliaction',
          title: '(MAP) Magilatech Accounting Package', 
          description: 'MAP platform allow you to automate your business process from sales and purchases to cash flow, VAT, EFD and reporting, MAP has features that support every step of your business processes.',
          imgUrl: 'img/portfolio/map.png',
          link: '/map',          
        },
      

        {
          type: 'Mobile Appliaction',
          title: 'TivoNivo (Social Networking Appliaction)', 
          description: 'TivoNivo is a highly interactive and friendly all-in-one secure light instant messaging app intended to make your life easier with features that make communication interesting beyond the regular instant messaging services.',
          imgUrl: 'img/portfolio/tivo.png',
          link: 'https://apps.apple.com/app/id1526983779',          
        },



        {
          type: 'Enterprise Appliaction',
          title: 'Magsec', 
          description: 'The magsec platform helps you monitor not just your known network but also find your devices across the Internet. Detect data leaks to the cloud, phishing websites,compromised databases and more. Magsec gives you the tools to monitor all your connected devices on the Internet.',
          imgUrl: 'img/portfolio/magsec.png',
          link: '/magsec',          
        },

        {
          type: 'Enterprise Appliaction',
          title: 'MagTrack (Vehicle Tracking Software)', 
          description: 'MagTrack is a GPS tracking platform that can satisfy any business requirements since it provides. Track and manage multiple objects, Accurate real-time positioning, S360° street view and traffic, Handy trip browsing, Events and parking, Trip statistics and generate various reports',
          imgUrl: 'img/portfolio/magtrack.png',
          link: '/magtrack',          
        }
                                                                                                          
      ]
    }
},
methods: {
    trimText(text) {
    return text.substr(0,200)+'...'
  },
}  
}
</script>