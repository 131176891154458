<template>
  <div>
    <section class="blog">
        <div class="container">
            <div class="row">
                <!-- Blog Content -->
                <div class="col-md-9">

                    <div class="blog-post">
                        <div class="mt20 mb20">
                            <img :src="uploadUrl+posts._embedded['wp:featuredmedia'][0].media_details.file" class="img-responsive width100"
                            alt="#">
                        </div>

                        <div class="post-header">
                            <h5><span>In</span> <strong><a href="#" class="color">{{ posts._embedded['wp:term'][0][0].name }}</a></strong></h5>
                            <a class="link-to-post" href="#"><h3><strong>{{ posts.title.rendered }}</strong></h3></a>
                        </div>

                        <div v-html="posts.content.rendered"></div>
                        <!-- <div class="post-tags">
                                <a v-for="(tag, index) in tags" :key="index" href="#" rel="tag">{{tag.name}}</a>
                            </div> -->
                        <!-- <div class="post-share">
                            <a href="blog-post-standard.html#"><i class="ion-social-facebook"></i> <span>Share</span></a>
                            <a href="blog-post-standard.html#"><i class="ion-social-twitter"></i> <span>Tweet</span></a>
                            <a href="blog-post-standard.html#"><i class="ion-social-pinterest"></i> <span>Pin it</span></a>
                            <a href="blog-post-standard.html#"><i class="ion-social-googleplus"></i></a>
                        </div> -->
                    </div>

                    <!-- Comments -->
                    <h5 class="comment-number"><strong> {{comments.length}} Comments:</strong></h5>
                    <ul v-for="(comment, index) in comments" :key="index" class="vos-comments">
                        <li class="post-comment">
                                <div class="post-body">
                                    <div class="comment-header">
                                        <span class="author"><a href="#">{{comment.author_name}}</a></span>
                                        <span class="time-ago"><a href="#">22 days ago</a></span>
                                    </div>
                                    <div class="post-message" v-html="comment.content.rendered">

                                    </div>
                                </div>
                        </li>
                    </ul>

                    <div class="form-leave-comment">                        
                        <h5><strong>Leave Comment</strong></h5>
                        <form 
                         class="php-email-form">
                          <div class="row">
                            <div class="form-group col-md-6">
                              <label for="name">Your Name</label>
                              <input type="text"  class="form-control" v-model="postComment.author_name"/>
                              <div class="validate"></div>
                            </div>
                            <div class="form-group col-md-6">
                              <label for="name">Your Email</label>
                              <input type="email" class="form-control" v-model="postComment.author_email"/>
                              <div class="validate"></div>
                            </div>
                          </div>
                          <div class="form-group">
                            <label for="name">Message</label>
                            <textarea class="form-control" rows="9"  v-model="postComment.content"></textarea>
                            <div class="validate"></div>
                          </div>
                          <div class="text-center"><button  id="submit" @click.prevent="saveComments">Send Comment</button>
                          </div>
                        </form>
                    </div>
                </div>
                <!-- End Blog Content -->

                <!-- Sidebar -->
                <div class="col-md-3 sidebar">

                    <div class="blog-widget">
                        <h5>About</h5>
                        <p>Magilatech is a software development and cyber security company with headquartered in Tanzania.
                                Founded in 2012 we have been passionately helping companies of all sizes across the globe to accomplish their digital transformation..</p>
                    </div>

                    <div class="blog-widget">
                        <h5>Categories</h5>
                        <ul class="category-list list-icons">
                            <li v-for="(category, index) in categories" :key="index">
                                <a href="#">
                                    <i class="ion-ios-arrow-right"></i> {{ category.name
                                    }}
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="blog-widget blog-tags">
                        <h5>Tags</h5>
                        <ul class="tags-list">
                            <li v-for="(tag, index) in tags" :key="index"><a href="#">{{tag.name}}</a></li>
                        </ul>
                    </div>
                </div>
                <!-- End Sidebar -->

            </div>
        </div>
    </section>
   <Footer/>
 </div> 
</template>
<script>
    import dataService from "@/services/dataService"
    import Footer from '@/views/home/footer.vue'
    
    export default {        
        components: {
            Footer
        },        
        data() {
            return {
                posts: [],
                comments: [],
                categories: [],
                tags: [],
                uploadUrl: "https://magilatech.co.tz/blog/wp-content/uploads/",
                postComment: {
                    post: "",
                    content: "",
                    author_name: "",
                    author_email: ""
                }
            };
        },

        methods: {
            retrievePosts(id) {
                    dataService.getPostByID(id)
                        .then(response => {
                            this.posts = response.data;
                            console.log(this.posts);
                        })
                        .catch(e => {
                            console.log(e);
                        });
                },

                retrieveComments(id) {
                    dataService.getComments(id)
                        .then(response => {
                            this.comments = response.data;
                            console.log(this.comments);
                        })
                        .catch(e => {
                            console.log(e);
                        });
                },

                saveComments() {
                  var data = {
                    post: this.$route.params.id,
                    content: this.postComment.content,
                    author_name: this.postComment.author_name,
                    author_email: this.postComment.author_email
                  };

                  if((this.$route.params.id=="")||
                    (this.postComment.content=="")||
                    (this.postComment.author_name="")||
                    (this.postComment.author_email=="")){
                    this.$toast.error("Please fill all required field ");
                  } 
                  else {                  
                  dataService.createComments(data)
                    .then(response => {
                        console.log(response.data);
                        this.$toast.success("Thank you! Your comment have been submited");
                        this.resetForm();
                    })
                    .catch(e => {
                        this.$toast.error(e.response.data.message);
                    });
                  }
                },
                
                resetForm() {
                    this.postComment.content=""
                    this.postComment.author_name=""
                    this.postComment.author_email=""
                },
 
                retrieveCategories() {
                    dataService.getCategories()
                        .then(response => {
                            this.categories = response.data;
                            console.log(this.categories);
                        })
                        .catch(e => {
                            console.log(e);
                        });
                },

                retrieveTags() {
                    dataService.getTags()
                        .then(response => {
                            this.tags = response.data;
                            console.log(this.tags);
                        })
                        .catch(e => {
                            console.log(e);
                        });
                },

                formatPost(post) {
                    return post.substr(10, 242);
                },
        },
        
        mounted() {
            this.retrievePosts(this.$route.params.id);
            this.retrieveComments(this.$route.params.id);
            this.retrieveCategories();
            this.retrieveTags();
        }
    };
</script>
<style>
    h2 {
        font-size: 18px;
        font-weight: bold;
        line-height: 1;
        letter-spacing: -0.05em;
    } 
</style>