<template>
    <nav class="navbar nav-down nav-small" 
         data-fullwidth="true"
         data-menu-style="light"
         data-animation="shrink">
    <div class="container">
      <div class="navbar-header">
        <div class="container">
          <button type="button" 
                  class="navbar-toggle collapsed" 
                  data-toggle="collapse" 
                  data-target="#navbar" 
                  aria-expanded="false" 
                  aria-controls="navbar"> 
                  <span class="sr-only">Toggle navigation</span> 
                  <span class="icon-bar top-bar"></span> 
                  <span class="icon-bar middle-bar"></span> 
                  <span class="icon-bar bottom-bar"></span> 
          </button>
          <a class="navbar-brand to-top" href='/'>
            <!-- <img src="img/assets/logo-light.png" class="logo-light" alt="#"> -->
            <img src="img/assets/logo-dark.png" class="logo-dark" alt="#">
          </a>
        </div>
      </div>
      <div id="navbar" class="navbar-collapse collapse">
        <div class="container">
          <ul class="nav navbar-nav center-menu">
              <li :class="activeLink('/')"><a href="/">Home</a></li>
              <li :class="activeLink('/about')"><a href="/about">About</a></li>
              <li :class="activeLink('/services')"><a href="/services">Services</a></li>
              <li :class="activeLink('/projects')"><a href="/projects">Products</a></li>
              <li :class="activeLink('/contact')"><a href="/contact">Contact</a></li>
              <!-- <li :class="activeLink('/news')"><a href="/news">Blog</a></li> -->
          </ul>
          <ul class="nav navbar-nav menu-right">
              <li :class="activeLink('/incident')">
                <a href="/incident">
                    <strong>Report An Incident</strong>
                </a>
            </li>
            <li class="nav-separator"></li>
            <li class="nav-icon">
              <a href="https://www.facebook.com/Magilatech/" target="_blank">
                <i class="ion-social-facebook"></i></a>
            </li>
            <li class="nav-icon">
              <a href="https://api.whatsapp.com/send?phone=255769555555&amp;text=Hi!%20Welcome%20To%20Magilatech" target="_blank" class="whatsapp">
                <img data-v-06101388="" src="img/assets/whatsapp.png" alt="#">
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>	
</template>
<script>
export default {
    name: "post-list",

    data() {
        return {
        }
    },

    computed: {
      // activeLink() {
      //   console.log(this.$route.fullPath)
      // }
    },

    methods:{
      activeLink(path) {
        if (path===this.$route.fullPath) {
          return 'active'
        }
        else{
          return ''
        }
      }
    },

    mounted() {
      this.activeLink
    },     
  }
</script>

<style>
.navbar-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.navbar-nav > ul {
  display: flex;
}

.navbar-nav > ul > li {
  position: relative;
  white-space: nowrap;
  padding: 10px 0 10px 28px;
}

.navbar-nav a {
  display: block;
  position: relative;
  color: #fff;
  transition: 0.3s;
  font-size: 15px;
  letter-spacing: 0.5px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
}

.navbar-nav  a:hover, .navbar-nav  .active > a, .navbar-nav li:hover > a {
  color: #0062cc;
}
</style>
