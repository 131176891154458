<template>
    <div>
        <section id="hero" class="hero-fullwidth parallax" data-overlay-dark="6">
            <div class="background-image">
              <vue-particles
                class="particles-js"
                color="#ffffff"
                :particleOpacity="0.7"
                linesColor="#ffffff"
                :particlesNumber="100"
                shapeType="circle"
                :particleSize="5"
                :linesWidth="2"
                :lineLinked="true"
                :lineOpacity="0.4"
                :linesDistance="150"
                :moveSpeed="6"
                :hoverEffect="true"
                hoverMode="grab"
                :clickEffect="true"
                clickMode="push"
              >
              </vue-particles>               
            </div>

            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <h1>Report  <strong>a cyber </strong>incident</h1>
                        <p class="lead">We have set of procedures and actions taken to respond to and resolve critical incidents, as we identify, analyze, and correct hazards to prevent a future re-occurrence</p>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Hero -->

        <!-- Portfolio -->
        <section id="contact" class="pt100 pb100 contact bg-grey">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center pb20">
                        <h3><strong>REPORT AN INCIDENT</strong></h3>
                        <p class="lead">We respect your privacy and Keep your data completely confidential.</p>
                    </div>
                </div>
                    <div class="row">
                      <div class="col-md-12">
                        <form class="php-email-form" @submit.prevent="submitMessage()">
                          <div class="row">
                            <div class="form-group col-md-12">
                              <label for="name">What has Happened ?</label>
                                <select class="form-control" v-model="incident.type" required>
                                  <option value="">Choose an incident type</option>
                                  <option value="Money Theft">Money Theft</option>
                                  <option value="Fraud">Fraud</option>
                                  <option value="Malware">Malware</option>
                                  <option value="Intrusion">Intrusion</option>
                              </select>
                              <div class="validate"></div>
                            </div>
                            <div class="form-group col-md-12">
                                <label for="name">Describe the Details</label>
                                <textarea class="form-control" rows="6" v-model="incident.detail" required></textarea>
                                <div class="validate"></div>
                            </div>                            
                            <div class="form-group col-md-6">
                              <label for="name">Email Address</label>
                              <input type="email" class="form-control" v-model="incident.email" required/>
                              <div class="validate"></div>
                            </div>
                          <div class="form-group col-md-6">
                            <label for="name">Mobile Phone</label>
                            <input type="text" class="form-control" v-model="incident.phone" required/>
                            <div class="validate"></div>
                          </div>
                          <div class="form-group col-md-12">
                            <label for="name">Organization Name (optional)</label>
                            <input type="text" class="form-control" v-model="incident.company" required/>
                            <div class="validate"></div>
                          </div>                          
                          <div class="text-center">
                            <button type="submit">Submit Incident</button>
                          </div>
                         </div>
                     </form>
                    </div>
                </div>
            </div>
        </section>     
      <Footer/>      
    </div>
</template>
<script>
import Footer from './home/footer.vue';
import dataService from "../services/dataService";
export default {
  components: {
    Footer
  },

  data() {
      return {
          incident: {
              type: "",
              detail: "",
              email: "",
              company: "",
              phone: ""
          }
      };
  },

  methods: {
    submitMessage() {
        var data = {
          type: this.incident.type,
          detail: this.incident.detail,
          email: this.incident.email,
          company: this.incident.company,
          phone: this.incident.phone
        };

        if((this.incident.type=="")||
          (this.incident.detail=="")||
          (this.incident.email=="")||
          (this.incident.company=="")||
          (this.incident.phone=="")){
          this.$toast.error("Please fill all required field ");
        }
        else{
        dataService.postInsident(data)
          .then(response => {
              console.log(response.data);
              this.$toast.success("Thank you! Your query have been submited");
              this.resetForm();
          })
          .catch(e => {
              //this.$toast.error(e.response.data.message);
              this.resetForm();
              this.$toast.success("Thank you! Your message have been submited");
          });  
        }       
    },

    resetForm() {
        this.incident.type=""
        this.incident.detail=""
        this.incident.email=""
        this.incident.company=""
        this.incident.phone=""
    }    
  }
}
</script>