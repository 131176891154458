<template>
    <div>
        <section id="hero" class="hero-fullwidth parallax" data-overlay-dark="6">
            <div class="background-image">
              <vue-particles
                class="particles-js"
                color="#ffffff"
                :particleOpacity="0.7"
                linesColor="#ffffff"
                :particlesNumber="100"
                shapeType="circle"
                :particleSize="5"
                :linesWidth="2"
                :lineLinked="true"
                :lineOpacity="0.4"
                :linesDistance="150"
                :moveSpeed="6"
                :hoverEffect="true"
                hoverMode="grab"
                :clickEffect="true"
                clickMode="push"
              >
              </vue-particles>               
            </div>

            <div class="container">
                <div class="row">

                    <div class="col-md-12 text-center">
                        <h1>What <strong>We</strong> Do</h1>
                        <p class="lead">We are tech company offering a variety of solutions in software development and cyber security.</p>
                    </div>

                </div>
            </div>
        </section>
        <!-- End Hero -->



        <!-- Blog -->
        <section class="pt110 pb90 bg-grey">
            <div class="container">
                <div class="row text-center">

                  <div class="col-md-12 text-center pb20">
                      <h3><strong>OUR SERVICES</strong></h3>
                      <p class="lead">We believe that our company’s guiding principles of transparency, accountability and responsiveness are core to ensuring successful software development and cybersecurity services that help our clients increase revenue, drive innovation and boost social impact. 

                        </p>
                  </div>


                    <div class="blog-grid">
                        <!-- Blog Items Container-->
                        <div class="vossen-blog-grid">
                            <!-- Blog Item -->
                            <div v-for="(service, index) in services" :key="index" class="col-md-4 col-sm-6">
                                <div class="blog-grid-img">
                                    <a :href="service.link"><img :src="service.imgUrl" class="img-responsive width100" alt="#"></a>
                                </div>
                                <div class="blog-grid-content">
                                    <div class="post-header">
                                    </div>
                                    <p>{{ trimText(service.description) }}</p>
                                    <div class="post-header">
                                        <h5>
                                            <strong>
                                            <a :href="service.link" class="color">Read more</a>
                                           </strong>
                                       </h5>
                                    </div>                                    
                                </div>
                            </div>
                            <!-- Blog Item -->
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End Blog -->

      <Footer/>      
    </div>
</template>
<script>
import Footer from './home/footer.vue'
import serviceUtil from '@/helpers/serviceUtil.js'
export default {
  components: {
    Footer
  },

  computed: {
    services () {
      return serviceUtil.services()
    }
  },

  methods: {
      trimText(text) {
        var v
      return text.substr(0,200)+'...'
    },
  },

mounted () {

}  

}
</script>