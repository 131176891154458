<template>
  <div>
    <about />
    <service />
    <focus />
    <portifolio />
    <social />
    <clients />
    <Footer />
  </div>
</template>
<script>
import About from './home/about.vue'
import Focus from './home/focus.vue'
import Service from './home/service.vue'
import Portifolio from './home/portfolio.vue'
import Clients from './home/clients.vue'
import Social from './home/social.vue'
import Contacts from './home/contacts.vue'
import Footer from './home/footer.vue'
export default {
  components: {
    About,
    Focus,
    Service,
    Portifolio,
    Clients,
    Social,
    Contacts,
    Footer
  }
}
</script>