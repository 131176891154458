<template>
	<div>
        <section id="focus" class="hero-fullscreen2 focus pt100 pb90 bg-grey">
        <div class="container">
            <div class="row text-center">
            <div class="col-md-12 text-center">
                <h3><strong>OUR SERVICES</strong></h3>
                
         <p class="lead">We believe that our company’s guiding principles of transparency, accountability and responsiveness are core to ensuring successful software development and cybersecurity services that help our clients increase revenue, drive innovation and boost social impact. </p>
               <!-- <p class="lead">We provide unrivaled <span class="color">technical services</span>. & Trusted advises to our clients.</p>-->
            </div>
                <div class="img-card" data-gap="20">
                    <div class="vossen-portfolio">
                        <!-- Portfolio Item -->
                        <div v-for="(service, index) in services" :key="index" class="col-md-4">
                            <a :href="service.link">
                                <div class="img-card-item">
                                    <div class="item-caption">
                                        <h4>{{ service.title }}</h4>
                                    </div>
                                    <div class="item-image"> <img alt="#" :src="service.imgUrl" /> </div>
                                </div>
                            </a>
                        </div>
                       <!-- Portfolio Item -->
                    </div>
                </div>
            </div>
        </div>
      </section>
	    <section id="siliconwing" class="parallax pt40 pb40" data-overlay-dark="6">
	        <div class="background-image"> <img src="img/assets/silconwing.jpg" alt="#"> </div>
	        <div class="container">
	            <div class="row vertical-align">
	                <div class="col-md-4 pr30 mt40 mb40"><img src="img/assets/silicon-logo.png" alt="#"> </div>
	                <div class="col-md-8 pt40 pb30">
	                    <h1>Our Innovation Space <strong>Silicon Wing</strong><br/></h1>
	                    <p class="lead">We are an Innovation Development Facility (IDF) supporting start-ups and SME’s through product development, commercialisation and deployment</p>
	                    <h5>
                        <a target="_blank" href="https://siliconwing.org" class="btn btn-md btn-primary btn-appear mt30"><span>Visit Site<i class="ion-checkmark"></i></span></a>
                        </h5>
	                </div>
	            </div>
	        </div>
	    </section>	
	</div>
</template>
<script>
import serviceUtil from '@/helpers/serviceUtil.js'
export default {
  computed: {
    services () {
      return serviceUtil.services()
    }
  }, 
}
</script>