<template> 
    <div> 
        <section class="blog">
            <div class="container">
                <div class="row">

                    <!-- Blog Content -->
                    <div class="col-md-9">

                        <div class="blog-post">
                            <div class="mt20 mb20">
                                <img src="img/services/software.jpg" class="img-responsive width100"
                                alt="#">
                            </div>

                            <div class="post-header">
                                <a class="link-to-post" href="#"><h3><strong>Software Development</strong></h3></a>
                            </div>
                            <p>We address the evolving social and business technology challenges by defining, designing and developing applications tailored to meet our community’s requirements.
                                </p>
                                    <blockquote>Why choose Magilatech to Outsource Software Development Services?</blockquote>
                                    <p>Magilatech has a rich experience of over 8 years in the field of application development, management,
                                     and integration, making us one of the most reputed enterprise application 
                                     integration services providers in the world with strong expertise in offering 
                                     end-to-end integration of heterogeneous applications and business processes through effective
                                      unification across the enterprise. Our scalable and extensive enterprise application integration
                                       solutions facilitate reduced operational cost and enhanced ROI for the businesses along with a smooth-functioning IT environment.
                                    </p>
                                    
                                    <blockquote>Outsource Software Development from Magilatech</blockquote>
                                    <p>Let Magilatech unleash the true potential of your enterprise and lead a successful application
                                        transformation for reduced costs of operations and greater returns on your company's investments.
                                        With an innovative and bold approach towards business software development, our team of expert designers and developers offer
                                        leading-edge ideas and solutions to scale-up your business performance through streamlined and integrated internal applications, 
                                        databases, and processes. Schedule an appointment with one of our experts today and get more information
                                        on our specialized enterprise software development services.
                                 </p>
                        </div>

                    </div>
                    <!-- End Blog Content -->

                    <!-- Sidebar -->
                    <div class="col-md-3 sidebar">

                        <div class="blog-widget">
                            <h5>About</h5>
                            <p>In 2012, Magilatech was founded with a clear mission to transform software development and cybersecurity solutions in Africa and beyond. Since its inception, Magilatech has grown from a startup incubatee, to a high growth technology company serving clients across the globe.</p>
                        </div>

                        <div class="blog-widget">
                            <h5>Quick Links</h5>
                            <ul class="category-list list-icons">
                                <li v-for="(service, index) in services" :key="index">
                                    <a :href="service.link"><i class="ion-ios-arrow-right"></i>{{ service.title }}</a>
                                </li>                            
                            </ul>
                        </div>

                    </div>
                    <!-- End Sidebar -->
                </div>
            </div>
        </section>
      <Footer/>      
    </div>
</template>
<script>
import Footer from '@/views/home/footer.vue'
import serviceUtil from '@/helpers/serviceUtil.js'
export default {
  components: {
    Footer
  },
  computed: {
    services () {
      return serviceUtil.services()
    }
  },
}
</script>