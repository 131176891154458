
 <template> 
   <div> 
        <section class="blog">
            <div class="container">
                <div class="row">

                    <!-- Blog Content -->
                    <div class="col-md-9">

                        <div class="blog-post">
                            <div class="mt20 mb20">
                                <img src="img/services/Group 2108.jpg" class="img-responsive width100"
                                alt="#">
                            </div>

                            <div class="post-header">
                                <a class="link-to-post" href="#"><h3><strong>AI,Big Data & Machine Learning</strong></h3></a>
                            </div>
                           
                           <p>We establish intelligent and influential solutions that are capable of strengthening functional efficiency and powering business growth.</p>
                           <p> 
                               Artificial Intelligence (AI), one of the latest technology trends that have stormed the business scenarios over the past few years. The technology enables computers to imitate human intelligence in a secure and reliable manner. Consequently, it unlocks new boundaries for businesses as they can take advantage of AI-driven applications to automate their business processes to drive advancement and efficiency.
                           </p>

                            <p>
                           We, at Magilatech, believe in delivering top-rated performance to our clients by offering superior AI solutions which precisely meet their requirements and maximize their ROI (Return on Investment) by automating their business processes. Our skill set expands to the whole series of AI technologies including Machine Learning, Speech Recognition, Natural Language Processing, and more. We design powerful and influential solutions that combine smoothly with the client’s business model and stimulate its growth in every possible way.
                           </p>

                           <blockquote>Artificial Intelligence Services and Solutions</blockquote>
                          

                          <p><b>Strategy
                           </b>We have Magilatech team of AI experts to assist your business to begin a transformational journey with the embracement of this advanced technology.
                            </p>

                            <p><b>Development
                           </b>We have expansive knowledge in developing advanced AI applications that enable the businesses to automate a variety of their operations.
                            </p>


                           <p><b>
                            Customization
                           </b>We are specialized in creating customized AI-driven solutions for our clients that are well suited to the unique requirements of their businesses and match the industry standards.
                            </p>
                             



                             <p><b>Implementation
                           </b>Our Magilatech team of AI experts makes sure that these advanced solutions are implemented in a manner that they deliver maximum benefits.
                            </p>

                            <p><b> Integration
                           </b>We tender flawless integration of the advanced AI solutions into the existing business model of the client to maximize the ROI.
                            </p>
                            
                            <blockquote>Our AI Solutions in Magilatech</blockquote>


                      

                           <p>
                              Magilatech Solutions provides AI Solutions help businesses gain superior and high-accuracy AI capabilities to clients from all over the world. Our team helps your businesses to utilize these capabilities to build extendable & cost-effective digital applications and minimize labor and infrastructure cost significantly.

                              If you're looking for high-quality and cost-effective embedded software development services, get in touch with us today!
                                </p>



                        </div>

                    </div>
                    <!-- End Blog Content -->

                    <!-- Sidebar -->
                    <div class="col-md-3 sidebar">

                        <div class="blog-widget">
                            <h5>About</h5>
                            <p>In 2012, Magilatech was founded with a clear mission to transform software development and cybersecurity solutions in Africa and beyond. Since its inception, Magilatech has grown from a startup incubatee, to a high growth technology company serving clients across the globe.</p>
                        </div>

                        <div class="blog-widget">
                            <h5>Quick Links</h5>
                            <ul class="category-list list-icons">
                                <li v-for="(service, index) in services" :key="index">
                                    <a :href="service.link"><i class="ion-ios-arrow-right"></i>{{ service.title }}</a>
                                </li>                            
                            </ul>
                        </div>

                    </div>
                    <!-- End Sidebar -->
                </div>
            </div>
        </section>
      <Footer/>      
    </div>
</template>
<script>
import Footer from '@/views/home/footer.vue'
import serviceUtil from '@/helpers/serviceUtil.js'
export default {
  components: {
    Footer
  },
 computed: {
    services () {
      return serviceUtil.services()
    }
  },
}
</script>