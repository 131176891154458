
 <template> 
   <div> 
        <section class="blog">
            <div class="container">
                <div class="row">

                    <!-- Blog Content -->
                    <div class="col-md-9">

                        <div class="blog-post">
                            <div class="mt20 mb20">
                                <img src="img/services/Group 2096.jpg" class="img-responsive width100"
                                alt="#">
                            </div>

                            <div class="post-header">
                                <a class="link-to-post" href="#"><h3><strong>Computer Forensics</strong></h3></a>
                            </div>

                            <p>In Magilatech we have track record combined with the latest forensic tools and technical expertise, security and investigations.</p>

                        <p>
                       Our Magilatech forensic systems are designed for portability and power when you need work done at another location. Our investment in high speed processing systems and professional software give you an advantage to have your results processed more quickly than others.
                        </p>

                        <blockquote>At Magilatech We Offer Computer Forensics Services</blockquote>

                        <p><b>Disk Forensics:</b>
                          The process of acquiring and analyzing the data stored on physical storage media (computer hard drive, cell phones, PDAs, removable media, etc.). Disk forensics includes both the recovery of hidden and deleted data and also file identification.
                        </p>

                        <p><b>Network Forensics:</b>
                          The process of examining network traffic, including transaction logs and real-time monitoring, using sniffers and tracing.</p>

                          <p><b>Internet Forensics:</b> The process of piecing together where and when a user has been on the Internet or internal company network. This is used to determine whether inappropriate Internet content access and downloading was inadvertent or not. It is also used to determine if sensitive information was emailed inappropriately using a personal email account..
                        </p>

                        <p><b>Email Forensics:</b>
                         The study of source and content of electronic mail as evidence. It includes the process of identifying the actual sender, recipient, date, time and location and from where the email originated. Email has become a significant issue for individuals and organizations. Harassment, discrimination or unauthorized activity violating company policy can be identified via email forensics
                        </p>

                        <p><b>Cloud Forensics: </b>
                         The process of acquiring and analyzing the data stored in cloud servers. CFS has conducted numerous cloud acquisitions and understands the legal and ethical considerations surrounding this form of preservation.
                        </p>


                                <blockquote>Contact Magilatech for Best Computer Forensics Services</blockquote>

                                  <p>

                                  The Computer forensic examination of electronic systems has undoubtedly been a huge success in the identification of cyber and computer-assisted crime. Organisations are placing an increasing importance on the need to be equipped with appropriate incident management capabilities to handle misuse of systems.</p>
                                 
                                  <p>
                                  As Magilatech we can help you re-invent and reshape your legacy application to help you meet the demanding needs of the digital world. Our innovative solutions and cutting-edge technologies help us get through even the trickiest modernization challenges smoothly and efficiently. Having successfully remodeled thousands of business applications and served hundreds of global clients, our expert Magilatech engineers have extensive computer forensics  experience. Leveraging their business insight and practical knowledge, they can help you quickly revamp your disparate processes for sure-shot business success.</p>
                                   <p>

                                    <p>
                                   So, Get in touch! With data, applications, and business processes at the core, we can help you enhance your organization's computer forensics. Simply let us know your requirements and budget and we'll get in touch with you with a good computer forensic services. May you Get in touch with us.

                                </p>
                                    

                            <!--<p>Your enterprise ecosystem depends highly on the functioning of your applications and their subsequent interaction with other legacy applications. 
                                Your company needs an agile environment for seamless sharing of data and information through applications for business operations to run smoothly.
                                 This can be achieved only when your enterprise applications are efficiently integrated and perform at par with the required standards.
                                  The growing IT ecosystem complexities within your enterprise, complicated hybrid approaches,
                                   availability of a multitude of cloud system versions, high costs of application management,
                                    etc., are roadblocks in the effective working of your enterprise application integration model. 
                                    These problems can be resolved when you have an expert managing it all for you.
                                </p>
                                    <blockquote>Why choose Magilatech to Outsource Application Integration Services?</blockquote>
                                    <p>Magilatech has a rich experience of over 10 years in the field of application development, management,
                                     and integration, making us one of the most reputed enterprise application 
                                     integration services providers in the world with strong expertise in offering 
                                     end-to-end integration of heterogeneous applications and business processes through effective
                                      unification across the enterprise. Our scalable and extensive enterprise application integration
                                       solutions facilitate reduced operational cost and enhanced ROI for the businesses along with a smooth-functioning IT environment.
                                    </p>
                                    
                                    <blockquote>Outsource Application Integration Services from Magilatech</blockquote>
                                    <p>Let Magilatech Solutions unleash the true potential of your enterprise and lead a successful application
                                        transformation for reduced costs of operations and greater returns on your company's investments.
                                        With an innovative and bold approach towards business application integration, our team of expert designers and developers offer
                                        leading-edge ideas and 




                                         to scale-up your business performance through streamlined and integrated internal applications, 
                                        databases, and processes. Schedule an appointment with one of our experts today and get more information
                                        on our specialized enterprise application integration services.
                                 </p>-->
                        </div>

                    </div>
                    <!-- End Blog Content -->

                    <!-- Sidebar -->
                    <div class="col-md-3 sidebar">

                        <div class="blog-widget">
                            <h5>About</h5>
                            <p>In 2012, Magilatech was founded with a clear mission to transform software development and cybersecurity solutions in Africa and beyond. Since its inception, Magilatech has grown from a startup incubatee, to a high growth technology company serving clients across the globe.</p>
                        </div>

                        <div class="blog-widget">
                            <h5>Quick Links</h5>
                            <ul class="category-list list-icons">
                                <li v-for="(service, index) in services" :key="index">
                                    <a :href="service.link"><i class="ion-ios-arrow-right"></i>{{ service.title }}</a>
                                </li>                            
                            </ul>
                        </div>

                    </div>
                    <!-- End Sidebar -->
                </div>
            </div>
        </section>
      <Footer/>      
    </div>
</template>
<script>
import Footer from '@/views/home/footer.vue'
import serviceUtil from '@/helpers/serviceUtil.js'
export default {
  components: {
    Footer
  },
  computed: {
    services () {
      return serviceUtil.services()
    }
  },
}
</script>