import Vue from 'vue'
import Router from 'vue-router'

// ROUTES
import home from '@/views/home.vue'
import about from '@/views/about.vue'
import service from '@/views/service.vue'
import project from '@/views/project.vue'
import client from '@/views/clients.vue'
import contact from '@/views/contact.vue'
import blog from '@/views/blog/home.vue'
import incident from '@/views/incident.vue'
import blog_detail from '@/views/blog/blog.vue'

// Projects
import ais from '@/views/projects/ais.vue'
import besp from '@/views/projects/besp.vue'
import lgrcis from '@/views/projects/lgrcis.vue'
import magtrack from '@/views/projects/magtrack.vue'
import map from '@/views/projects/map.vue'
import myworth from '@/views/projects/myworth.vue'
import prms from '@/views/projects/prms.vue'
import pspf from '@/views/projects/pspf.vue'
import repository from '@/views/projects/repository.vue'
import rms from '@/views/projects/rms.vue'
import tigo_backup from '@/views/projects/tigo-backup.vue'
import visomo from '@/views/projects/visomo.vue'
import magsec from '@/views/projects/magsec.vue'

// Services
import software from '@/views/services/software.vue'
import cyber from '@/views/services/cyber.vue'
import custom from '@/views/services/custom.vue'
import mobile_app from '@/views/services/mobile-app.vue'
import embedded from '@/views/services/embedded.vue'
import maintainance from '@/views/services/maintainance.vue'
import intergration from '@/views/services/intergration.vue'
import AI from '@/views/services/AI.vue'
import enterprise from '@/views/services/enterprise.vue'
import re_engineering from '@/views/services/re-engineering.vue'
import computer_forensics from '@/views/services/computer-forensics.vue'
import banking from '@/views/services/banking.vue'
import annual from '@/views/services/annual.vue'
import penetration from '@/views/services/penetration.vue'
import fraud from '@/views/services/fraud.vue'
import mobile_phone from '@/views/services/mobile-phone.vue'
import governance from '@/views/services/governance.vue'
import auditing from '@/views/services/auditing.vue'
import vulnerability from '@/views/services/vulnerability.vue'
import incidents from '@/views/services/incidents.vue'
import VR from '@/views/services/VR.vue'
import policy from '@/views/policy.vue'


Vue.use(Router)

const router = new Router({
  mode: 'history',
  //base: '/magilatech',
  routes: [
    { path: '/', component: home },
    { path: '/about', component: about },
    { path: '/services', component: service },
    { path: '/projects', component: project },
    { path: '/clients', component: client },
    { path: '/contact', component: contact },
    { path: '/incident', component: incident },
    { path: '/news', component: blog },

    

    // Projects Routes
    { path: '/ais', component: ais },
    { path: '/besp', component: besp },
    { path: '/lgrcis', component: lgrcis },
    { path: '/magtrack', component: magtrack },
    { path: '/map', component: map },
    { path: '/myworth', component: myworth },
    // { path: '/prms', component: prms },
    { path: '/pspf', component: pspf },
    { path: '/repository', component: repository },
    { path: '/rms', component: rms },
    { path: '/tigo-backup', component: tigo_backup },
    { path: '/visomo', component: visomo },
    { path: '/magsec', component: magsec },
   


    // Services Routes
    { path: '/software', component: software },
    { path: '/cyber', component: cyber },
    { path: '/custom', component: custom },
    { path: '/mobile-app', component: mobile_app }, 
    { path: '/embedded', component:embedded  },
    { path: '/maintainance', component: maintainance },
    { path: '/intergration', component: intergration },
    { path: '/AI', component: AI },
    { path: '/enterprise', component: enterprise },
    { path: '/re-engineering', component: re_engineering },
    { path: '/computer-forensics', component: computer_forensics },
    { path: '/banking', component: banking },
    { path: '/annual', component: annual }, 
    { path: '/penetration', component: penetration },
    { path: '/fraud', component: fraud },
    { path: '/mobile-phone', component: mobile_phone },
    { path: '/governance', component: governance },
    { path: '/auditing', component: auditing },
    { path: '/vulnerability', component: vulnerability },
    { path: '/incidents', component: incidents },
    { path: '/VR', component: VR },
    { path: '/policy', component: policy },

    { 
      path: '/blog-details/:id',
      component: blog_detail,
      name: 'blog-details'
    },
  ]

});


export default router;