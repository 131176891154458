<template>
    <div>
        <section id="hero" class="hero-fullwidth parallax" data-overlay-dark="6">
            <div class="background-image">
              <vue-particles
                class="particles-js"
                color="#ffffff"
                :particleOpacity="0.7"
                linesColor="#ffffff"
                :particlesNumber="100"
                shapeType="circle"
                :particleSize="5"
                :linesWidth="2"
                :lineLinked="true"
                :lineOpacity="0.4"
                :linesDistance="150"
                :moveSpeed="6"
                :hoverEffect="true"
                hoverMode="grab"
                :clickEffect="true"
                clickMode="push"
              >
              </vue-particles>               
            </div>

            <div class="container">
                <div class="row">

                    <div class="col-md-12 text-center">
                        <h1>Let's  <strong>Connect</strong></h1>
                        <p class="lead">We would be happy to hear from you.</p>
                    </div>

                </div>
            </div>
        </section>
        <!-- End Hero -->

        <!-- Portfolio -->
        <section id="contact" class="pt100 pb100 contact bg-grey">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center pb20">
                        <h3><strong>CONTACT US</strong></h3>
                        <p class="lead">We respect your privacy and Keep your data completely confidential.</p>
                    </div>
                </div>
                    <div class="row">
                      <div class="col-md-5">
                        <div class="info">
                          <div class="address">
                            <i class="icon-map-pin"></i>
                            <h4>Location:</h4>
                            <p>14th Floor Noble Centre<br>Victoria - Makumbusho<br>Dar es Saalam</p>
                          </div>
                          <iframe src="https://www.google.com/maps/embed/v1/place?q=Magilatech%20Company%20Ltd%20HQ%2C%20Dar%20es%20Salaam&key=AIzaSyC82SBa1sGcoZ9DrW3BHuLBLyStJte55k4" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0">
                          </iframe>
                        </div>
                      </div>
                      <div class="col-md-7">
                        <form class="php-email-form" @submit.prevent="submitMessage()">
                          <div class="row">
                            <div class="form-group col-md-6">
                              <label for="name">Your Name</label>
                              <input type="text" name="name" class="form-control" v-model="contact.name" required/>
                              <div class="validate"></div>
                            </div>
                            <div class="form-group col-md-6">
                              <label for="name">Your Email</label>
                              <input type="email" class="form-control" v-model="contact.email" required/>
                              <div class="validate"></div>
                            </div>
                          </div>
                          <div class="form-group">
                            <label for="name">Subject</label>
                            <input type="text" class="form-control" v-model="contact.subject" required/>
                            <div class="validate"></div>
                          </div>
                          <div class="form-group">
                            <label for="name">Message</label>
                            <textarea class="form-control" rows="15" v-model="contact.message" required></textarea>
                            <div class="validate"></div>
                          </div>
                          <div class="text-center"><button type="submit">Send Message</button></div>
                        </form>
                      </div>
                </div>
            </div>
        </section>     
      <Footer/>      
    </div>
</template>
<script>
import Footer from './home/footer.vue';
import dataService from "../services/dataService";
export default {
  components: {
    Footer
  },

  data() {
      return {
          contact: {
              name: "",
              email: "",
              subject: "",
              message: ""
          }
      };
  },

  methods: {
    submitMessage() {
        var data = {
          name: this.contact.name,
          email: this.contact.email,
          message: this.contact.message,
          subject: this.contact.subject
        };

        if((this.contact.name=="")||
          (this.contact.email=="")||
          (this.contact.message=="")||
          (this.contact.subject=="")){
          this.$toast.error("Please fill all required field ");
        }
        else{
        dataService.postContact(data)
          .then(response => {
              console.log(response.data);
              this.$toast.success("Thank you! Your message have been submited");
              this.resetForm();
          })
          .catch(e => {
              //this.$toast.error(e.response.data.message);
              this.resetForm();
              this.$toast.success("Thank you! Your message have been submited");
          });
       }        
    },

    resetForm() {
        this.contact.name=""
        this.contact.email=""
        this.contact.message=""
        this.contact.subject=""
    }    

  }
}
</script>