
 <template> 
   <div> 
        <section class="blog">
            <div class="container">
                <div class="row">

                    <!-- Blog Content -->
                    <div class="col-md-9">

                        <div class="blog-post">
                            <div class="mt20 mb20">
                                <img src="img/services/Group 2103.jpg" class="img-responsive width100"
                                alt="#">
                            </div>

                            <div class="post-header">
                                <a class="link-to-post" href="#"><h3><strong>System Auditing</strong></h3></a>
                            </div>

                              <p>As part of our Consultancy Services we offer Systems Audits to evaluate and improve on the effectiveness of a system. As a leader in software development, we understand vulnerabilities that applications harbor and through our offering of Vulnerability Assessment and Penetration Testing, we extend this knowledge and experience by providing application, network and wireless penetration testing services. We promote security awareness and skills through specialized training of personnel on our Security Capability Development program, which is a model that guarantees a complete organizational security awareness</p>

                                <p>Magilatech  is a leading system audit service provider and can help you address a wide range of system audit requirements. Our Magilatech system audit team is highly experienced and has a thorough understanding of all the system audit best practices. We can perform a thorough check of your systems to ensure that they are performing optimally and that you are getting the most from your investment and your IT systems.</p>


                                <blockquote>Our Magilatech system Audit Services</blockquote>

                                <p><b>System Infrastructure Controls Audit</b>
                                 Our Magilatech system audit team will examine all the controls within your firm's IT infrastructure. During this process, we will evaluate the overall functioning of your IT control environment to ensure that the system has been properly set up to preserve the confidentiality and integrity of your critical systems and data. Our team will work closely with you to ensure that the system audit process is both cost-effective and performed within a quick turnaround time.
                                </p>


                                <p><b>IT General Controls Audit</b>
                                Here, we evaluate and analyze the controls related to your IT infrastructure. Our system audit team will analyze your information systems policies, controls, and infrastructure to ensure that mission-critical systems and data are available, confidential, and have integrity. The scope of the audit is based on the size and scope of your firm's operations, but we analyze and evaluate three main control domains - protective controls, detective controls, and corrective controls.
                                </p>


                                <p><b>IT Compliance Analysis</b>
                                 In our compliance review, we examine your firm's existing controls, methods, and policies and compare them against industry best practices and regulations related to cyber and information security. With our services, you can understand whether your IT policies and systems adhere to state, federal, and industry guidelines. We also identify gaps in your IT deployment, suggest areas of improvement, and offer solutions on how to bring your business into compliance.
                                </p>



                              <p><b>Comprehensive system Audit</b>
                                 We will perform a comprehensive and in-depth system audit and provide an system audit report that presents a complete picture of the state of your Servers, Laptops and Desktops, Network and Security, Applications, Licensing Hosting, Internet Access, Backup and Disaster Recovery, Telephony, IT Team, and IT Suppliers and Costs
                                </p>




                                <blockquote>Why Outsource system Audit Services to Magilatech?</blockquote>

                                <p><b>Cost-effective Pricing</b>
                                 We provide our system audit solutions at extremely affordable prices, providing you with significant cost savings.
                                </p>


                                <p><b>Data Security</b>
                               Being a leading system audit service providing company, we take the security of your data extremely seriously.
                                </p>



                                <p><b>High-quality Services</b>
                                 Our guarantee is that you will always receive the highest quality and personalized system audit services when you outsource to us.
                                </p>

                                <p><b>World-class Infrastructure</b>
                                Our Magilatech system audit team works out of world-class offices that house the latest infrastructure facilities and amenities, enabling them to provide world-class services.

                                </p>

                                <p><b>
                                Quick Turnaround Time</b>
                                 It is always a milestone for us to deliver our system audit services ahead of schedule and within budget.
                                </p>

                                <p><b>Expert Team</b>
                                Our Magilatech system audit team is comprised of highly experienced technology professionals that have significant experience in providing a range of system audit services.
                                </p>

                              <blockquote>Outsource System Audit Services to Magilatech</blockquote>

                              <p>Magilatech provides highly customized and robust system audit services to clients from across the world. Our system audit team is highly experienced and versatile when it comes to providing customized system audit services and are fully aware of all the ongoing developments in the system audit field. We work closely with our clients to determine the best system audit strategy that is both cost-effective and timely.

                                    If you're looking for high-quality and robust system audit services, get in touch with us today!</p>                           
                        </div>
                    </div>
                    <!-- End Blog Content -->

                    <!-- Sidebar -->
                    <div class="col-md-3 sidebar">

                        <div class="blog-widget">
                            <h5>About</h5>
                            <p>In 2012, Magilatech was founded with a clear mission to transform software development and cybersecurity solutions in Africa and beyond. Since its inception, Magilatech has grown from a startup incubatee, to a high growth technology company serving clients across the globe.</p>
                        </div>

                        <div class="blog-widget">
                            <h5>Quick Links</h5>
                            <ul class="category-list list-icons">
                                <li v-for="(service, index) in services" :key="index">
                                    <a :href="service.link"><i class="ion-ios-arrow-right"></i>{{ service.title }}</a>
                                </li>                            
                            </ul>
                        </div>

                    </div>
                    <!-- End Sidebar -->
                </div>
            </div>
        </section>
      <Footer/>      
    </div>
</template>
<script>
import Footer from '@/views/home/footer.vue'
import serviceUtil from '@/helpers/serviceUtil.js'
export default {
  components: {
    Footer
  },
  computed: {
    services () {
      return serviceUtil.services()
    }
  },
}
</script>