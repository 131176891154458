
 <template> 
   <div> 
        <section class="blog">
            <div class="container">
                <div class="row">

                    <!-- Blog Content -->
                    <div class="col-md-9">

                        <div class="blog-post">
                            <div class="mt20 mb20">
                                <img src="img/services/cyber.jpg" class="img-responsive width100"
                                alt="#">
                            </div>

                            <div class="post-header">
                                <a class="link-to-post" href="#"><h3><strong>Cyber Security</strong></h3></a>
                            </div>
                            <p>We provide comprehensive penetration testing, vulnerability assessment, malware analysis and cryptography services. Our state-of-the-art Cybersecurity Lab applies current research results to ensure the best protection of our products and clients.
                                </p>
                                    <blockquote>Why choose Magilatech to Outsource Cyber Security Services?</blockquote>
                                    <p>Magilatech has a rich experience of over 8 years in the field of application development and cyber security, making us one of the most reputed enterprise application 
                                     security services providers in the world with strong expertise in offering 
                                     end-to-end cyber security solutions to help organizations protect their valuable assets..
                                    </p>
                                    
                                    <blockquote>Outsource Cyber Security from Magilatech</blockquote>
                                    <p>Let Magilatech unleash the true potential of your enterprise and lead a successful application
                                        transformation for reduced costs of operations and greater security of your company's applications.
                                        With an innovative and bold approach towards business application security. Schedule an appointment with one of our experts today and get more information
                                        on our specialized enterprise cyber security services.
                                 </p>
                        </div>

                    </div>
                    <!-- End Blog Content -->

                    <!-- Sidebar -->
                    <div class="col-md-3 sidebar">

                        <div class="blog-widget">
                            <h5>About</h5>
                            <p>In 2012, Magilatech was founded with a clear mission to transform software development and cybersecurity solutions in Africa and beyond. Since its inception, Magilatech has grown from a startup incubatee, to a high growth technology company serving clients across the globe.</p>
                        </div>

                        <div class="blog-widget">
                            <h5>Quick Links</h5>
                            <ul class="category-list list-icons">
                                <li v-for="(service, index) in services" :key="index">
                                    <a :href="service.link"><i class="ion-ios-arrow-right"></i>{{ service.title }}</a>
                                </li>                            
                            </ul>
                        </div>

                    </div>
                    <!-- End Sidebar -->
                </div>
            </div>
        </section>
      <Footer/>      
    </div>
</template>
<script>
import Footer from '@/views/home/footer.vue'
import serviceUtil from '@/helpers/serviceUtil.js'
export default {
  components: {
    Footer
  },
  computed: {
    services () {
      return serviceUtil.services()
    }
  },
}
</script>