<template>
  <div>
    <!-- Blog -->
    <section class="blog">
        <div class="container">
            <div class="row">
                <div class="col-md-9">
                    <ul class="blog-list">
                        <li v-for="(post, index) in posts" :key="index" class="row vertical-align">
                            <div class="col-md-6 mt20 mb20">
                                <router-link :to="{ 
                                            name: 'blog-details',
                                            params: { id: post.id }
                                        }">
                                    <img :src="uploadUrl+post._embedded['wp:featuredmedia'][0].media_details.file" class="img-responsive width100"
                                    alt="#">
                                </router-link>
                            </div>
                            <div class="col-md-6">
                                <div class="">
                                    <h5>
                                        <span>In</span>
                                        <strong>
                                        <router-link 
                                        :to="{ 
                                            name: 'blog-details',
                                            params: { id: post.id }
                                        }"
                                         class="color"> {{ post._embedded['wp:term'][0][0].name }}</router-link>
                                       </strong>
                                   </h5>
                                    <router-link :to="{ 
                                            name: 'blog-details',
                                            params: { id: post.id }
                                        }">
                                        <h3><strong>{{ post.title.rendered }}</strong></h3>
                                    </router-link>
                                </div>
                                <div v-html="formatPost(post.content.rendered)"></div>
                                <div class="post-header">
                                    <h5>
                                        <strong>
                                        <router-link 
                                        :to="{ 
                                            name: 'blog-details',
                                            params: { id: post.id }
                                        }"
                                         class="color">Read more</router-link>
                                       </strong>
                                   </h5>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <!-- Pagination -->
<!--                     <div class="col-md-12 text-center">
                        <ul class="blog-pagination mb60">
                            <li>
                                <a href="blog-list.html#">
                                    <i class="ion-android-arrow-back"></i>
                                </a>
                            </li>
                            <li class="active">
                                <a href="blog-list.html">1</a>
                            </li>
                            <li>
                                <a href="blog-list.html#">2</a>
                            </li>
                            <li>
                                <a href="blog-list.html#">3</a>
                            </li>
                            <li>
                                <a href="blog-list.html#">4</a>
                            </li>
                            <li>
                                <a href="blog-list.html#">5</a>
                            </li>
                            <li>
                                <a href="blog-list.html#">
                                    <i class="ion-android-arrow-forward"></i>
                                </a>
                            </li>
                        </ul>
                    </div> -->
                    <!-- End Pagination -->

                </div>

                <!-- Sidebar -->
                <div class="col-md-3 sidebar" v-if="posts">
                    <div class="blog-widget" v-if="posts">
                        <h5>About</h5>
                        <p>Magilatech is a software development and cyber security company with headquartered in Tanzania.
                                Founded in 2012 we have been passionately helping companies of all sizes across the globe to accomplish their digital transformation..</p>
                    </div>

                    <div class="blog-widget" v-if="posts">
                        <h5>Categories</h5>
                        <ul class="category-list list-icons">
                            <li v-for="(category, index) in categories" :key="index">
                                <a href="#">
                                    <i class="ion-ios-arrow-right"></i> {{ category.name
                                    }}
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="blog-widget blog-tags" v-if="posts">
                        <h5>Tags</h5>
                        <ul class="tags-list">
                            <li v-for="(tag, index) in tags" :key="index"><a href="#">{{tag.name}}</a></li>
                        </ul>
                    </div>
                </div>
                <!-- End Sidebar -->
            </div>
        </div>
    </section>
    <!-- End Blog -->
   <Footer/>
  </div>  
</template>

<script>
    import dataService from "@/services/dataService"
    import Footer from '@/views/home/footer.vue'
    export default {
        components: {
            Footer
        }, 
        data() {
            return {
                posts: [],
                categories: [],
                tags: [],
                uploadUrl: "https://magilatech.co.tz/blog/wp-content/uploads/",
            };
        },
        methods: {
            retrievePosts() {
                    dataService.getPost()
                        .then(response => {
                            this.posts = response.data;
                            console.log(this.posts);
                        })
                        .catch(e => {
                            console.log(e);
                        });
                },

                retrieveCategories() {
                    dataService.getCategories()
                        .then(response => {
                            this.categories = response.data;
                            console.log(this.categories);
                        })
                        .catch(e => {
                            console.log(e);
                        });
                },

                retrieveTags() {
                    dataService.getTags()
                        .then(response => {
                            this.tags = response.data;
                            console.log(this.tags);
                        })
                        .catch(e => {
                            console.log(e);
                        });
                },

                retrievePostByID(id) {
                    dataService.getPostByID(id)
                        .then(response => {
                            this.dataService = response.data;
                            console.log(response.data);
                        })
                        .catch(e => {
                            console.log(e);
                        });
                },

                getPostByID() {
                    return this.templateImg = "img/blog/1.jpg";
                },


                formatPost(post) {
                    return post.substr(0,277);
                },


        },
        mounted() {
            this.retrievePosts();
            this.retrieveCategories();
            this.retrieveTags();
        }
    };
</script>