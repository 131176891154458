
 <template> 
   <div> 
        <section class="blog">
            <div class="container">
                <div class="row">

                    <!-- Blog Content -->
                    <div class="col-md-9">

                        <div class="blog-post">
                            <div class="mt20 mb20">
                                <img src="img/services/Group 2110.jpg" class="img-responsive width100"
                                alt="#">
                            </div>

                            <div class="post-header">
                                <a class="link-to-post" href="#"><h3><strong>Mobile App Development</strong></h3></a>
                            </div>

                            <p>
                            Are you looking to develop a professional & innovative mobile app for your business or create a gaming app? Or do you have a mobile app on one platform and want to expand your reach by developing an app on other platforms as well? Magilatech mobile app development services are here for you, For over a eight years, we have been developing numerous mobile applications for global clients belonging to different industries, helping their business move towards profitability, and can do the same for you. Our services include :-
                            <br>
                            1. IOS APP
                            <br>
                            2. ANDROID APP
                            <br>
                            3. HYBRID APP
                            <br>
                            4. NATIVE APP

                            </p>
                                  
                            
                            


                        
                              <blockquote>Why Choose Magilatech for Mobile App Development?</blockquote>

                  

                              <p><b>Dedicated Team: </b> Before starting a project, we assemble a team which fits your exact requirements and works dedicatedly on your project. Our Magilatech developers have a lot of experience and are expertise in mobile application development.
                              </p>
                              <br>
                              <p><b> Lean Development Phase:</b> Software development is a dynamic process, and it is impossible to anticipate each and every scenario that might occur during development. However, years of domain expertise, presence of an experienced team and our reliance on proven development methodologies ensures delivery of a high quality mobile application at all times.
                              </p>
                              <br>
                              <p><b>End-to-End Solutions: </b> Developing a mobile application is only a part of the process. At Magilatech, based upon our clients requirements, we can also provide on-going support for apps including regular maintenance and updates so as to engage as well as drawn-in new users.
                              </p>
                              <br>
                              <p><b> Competitive Pricing:</b> Our services are highly cost-effective and reliable, ensuring you receive the highest return on your investment with the help of a feature-complete mobile app delivered within the requested time period.
                              </p>
                              <br>
                               <p><b>Transparency:</b> From regular Skype/conference calls, email updates, daily status reports, and even onsite visits if necessary, our dedicated project managers ensure you are always kept within the loop during each and every stage of the product development cycle. As a result, the final build of the mobile application is always as per your requirements.
                              </p>

                              <blockquote>Outsource Mobile App Development</blockquote>


                             
                                <p>
                             At Magilateh, we follow industry best practices and offer you nothing but the best services, while still maintaining considerably low development costs as compared to our competitors. We have talented team mobile app developers, streamlined 8-step mobile app development processes, and more importantly, the ability to scale up to meet your demands. Outsource mobile app development to us and get access to a series of benefits.

                            <br>
                            Get in touch with us now, and see for yourselves how our mobile application development services can help you expand your customer base, grow your business, and reach out to whole new demographics in as short a time as possible.

                                </p>


                            
                        </div>

                    </div>
                    <!-- End Blog Content -->

                    <!-- Sidebar -->
                    <div class="col-md-3 sidebar">

                        <div class="blog-widget">
                            <h5>About</h5>
                            <p>In 2012, Magilatech was founded with a clear mission to transform software development and cybersecurity solutions in Africa and beyond. Since its inception, Magilatech has grown from a startup incubatee, to a high growth technology company serving clients across the globe.</p>
                        </div>

                        <div class="blog-widget">
                            <h5>Quick Links</h5>
                            <ul class="category-list list-icons">
                                <li v-for="(service, index) in services" :key="index">
                                    <a :href="service.link"><i class="ion-ios-arrow-right"></i>{{ service.title }}</a>
                                </li>                            
                            </ul>
                        </div>

                    </div>
                    <!-- End Sidebar -->
                </div>
            </div>
        </section>
      <Footer/>      
    </div>
</template>
<script>
import Footer from '@/views/home/footer.vue'
import serviceUtil from '@/helpers/serviceUtil.js'
export default {
  components: {
    Footer
  },
 computed: {
    services () {
      return serviceUtil.services()
    }
  },
}
</script>