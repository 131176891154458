<template>
  <div>
      <footer id="footer">
        <div class="footer-top">
          <div class="container">
            <div class="row">

              <div class="col-lg-3 col-md-6 footer-contact">
                <h3>magilaTech</h3>
                <p>
                  14th Floor Noble Center <br>
                  Victoria, Makumbusho<br>
                  Dar es Saalam <br><br>
                  <strong>Phone 1:</strong> +255 769 555 555<br>
                  <strong>Phone 2:</strong> +255 752 341 257<br>
                  <strong>Email:</strong> info@magilatech.co.tz<br><br>
                </p>
                <div class="social-links text-md-right pt-3 pt-md-0">
                  <a target="_blank" href="https://twitter.com/search?q=%23MagilaTech" class="twitter"><i class="ion-social-twitter"></i></a>
                  <a target="_blank" href="https://www.facebook.com/Magilatech" class="facebook"><i class="ion-social-facebook"></i></a>
                  <a target="_blank" href="https://www.instagram.com/magilatech/?hl=en" class="instagram"><i class="ion-social-instagram"></i></a>
                  <a target="_blank" href="https://www.linkedin.com/company/magilatech/posts/?feedView=all" class="linkedin"><i class="ion-social-linkedin"></i></a>
                </div>

              </div>

              <div class="col-lg-2 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li><i class="bx bx-chevron-right"></i> <a href="/">Home</a></li>
                  <li><i class="bx bx-chevron-right"></i> <a href="/about">About us</a></li>
                  <li><i class="bx bx-chevron-right"></i> <a href="/services">Services</a></li>
                  <li><i class="bx bx-chevron-right"></i> <a href="/projects">Products</a></li>
                  <li><i class="bx bx-chevron-right"></i> <a href="/contact">Contact</a></li>
                </ul>
              </div>

              <div class="col-lg-3 col-md-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li><i class="bx bx-chevron-right"></i> <a href="/software">Software Development</a></li>
                  <li><i class="bx bx-chevron-right"></i> <a href="/mobile-app">Mobile App Development</a></li>
                  <li><i class="bx bx-chevron-right"></i> <a href="/enterprise">Enterprise Application</a></li>
                  <li><i class="bx bx-chevron-right"></i> <a href="/penetration">Penetration Testing</a></li>
                  <li><i class="bx bx-chevron-right"></i> <a href="/auditing">IT System Auditing </a></li>
                </ul>
              </div> 
              <div class="col-lg-4 col-md-6 footer-newsletter">
                <h4>About us</h4>
                <p>In 2012, Magilatech was founded with a clear mission to transform software development and cybersecurity solutions in Africa and beyond. Since its inception, Magilatech has grown from a startup incubatee, to a high growth technology company serving clients across the globe.</p>
 
                <div class="text-md-right" style="color:white;">
                  <a style="color:white;" target="_blank" href="/policy" class="twitter"><strong><i>Information Security Policy</i></strong></a>
                </div>                              
              </div>              
            </div>
          </div>
        </div>

        <div class="container d-md-flex py-4">
          <div class="mr-md-auto text-center text-md-left">
            <br>
            <div class="copyright">
              &copy; <strong><span> Magilatech {{ currentYear }}</span></strong>. All rights are reserved
            </div>
            <br>
          </div>
        </div>
      </footer>
  </div>
</template>
<script>
export default {
  computed: {
    currentYear () {
      var today = new Date();
      var year = today.getFullYear();      
      return year
    }
  },
  methods: {

    showPolicy(){
      document.location.href= '/policy';
    }
  }
}
</script>