import blogApi from "../http-blog-api";
import siteApi from "../http-site-api";

class dataService {

  getPost() {
    return blogApi.get("/posts?_embed");
  }
  
  getPostByID(id) {
    return blogApi.get(`/posts/${id}?_embed`)
  }

  getComments(id) {
    return blogApi.get(`/comments?post=${id}`)
  } 

  createComments(data) {
    return blogApi.post(`/comments`,data)
  }
  
  postContact(data) {
    return siteApi.post(`/contactService.php`,data)
  }  

  postInsident(data) {
    return siteApi.post(`/insidentService.php`,data)
  }  

  postSubscription(data) {
    return siteApi.post(`/subscription.php`,data)
  }

  getCategories() {
    return blogApi.get("/categories");
  }

  getTags() {
    return blogApi.get("/tags");
  }

}

export default new dataService();
