
<template>
    <div>
        <section id="hero" class="hero-fullwidth parallax" data-overlay-dark="6">
            <div class="background-image">
              <vue-particles
                class="particles-js"
                color="#ffffff"
                :particleOpacity="0.7"
                linesColor="#ffffff"
                :particlesNumber="100"
                shapeType="circle"
                :particleSize="5"
                :linesWidth="2"
                :lineLinked="true"
                :lineOpacity="0.4"
                :linesDistance="150"
                :moveSpeed="6"
                :hoverEffect="true"
                hoverMode="grab"
                :clickEffect="true"
                clickMode="push"
              >
              </vue-particles>               
            </div>

            <div class="container">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <h1>Information <strong>Security</strong> Policy</h1>
                    </div>

                </div>
            </div>
        </section>

        <section id="about" class="container pt100 pb90">
            <div class="col-md-12">
                <iframe src="pdf/policy.pdf" frameborder="0" style="width:100%;height:100vh;"></iframe>
            </div>
        </section>
<Footer/>        
</div>
</template>
<script>
import Footer from './home/footer.vue'
export default {
  components: {
    Footer
  }
}
</script>