let services = () => {

     let data = [
        {
          type: 'Software Development',
          title: 'Custom Software Development', 
          description: 'Custom software applications that raised specific enterprise needs have become an imperative component of the technology landscape today as the limitations of packaged software become apparent.',
          imgUrl: 'img/services/Group 2104.jpg',
          link: '/custom',          
        },
        {
          type: 'Software Development',
          title: 'Mobile App Development', 
          description: 'Are you looking to develop a professional & innovative mobile app for your business ? Or do you have a mobile app on one platform and want to expand your reach by developing an app on other platforms as well? Magilatech mobile app development services are what you need! For over a decade, we have been developing numerous mobile applications for global clients belonging to different industries, helping their business move towards profitability, and can do the same for you',
          imgUrl: 'img/services/Group 2110.jpg',
          link: '/mobile-app',          
        },        
        {
          type: 'Software Development',
          title: 'Embedded Systems', 
          description: 'Are you looking for embedded software development solutions for things like industrial and home automation applications, embedded silicon, consumer electronics products, and GPS systems? Do you need',
          imgUrl: 'img/services/Group 2105.jpg',
          link: '/embedded',          
        },
        {
          type: 'Software Development',
          title: 'Software Maintainance', 
          description: 'We proactively handle the maintenance of software to ensure that your software is bug-free at deployment. We follow the best application software maintenance practices to make your software robust and secure. Our software support services include.',
          imgUrl: 'img/services/Group 2106.jpg',
          link: '/maintainance',          
        },
        {
          type: 'Software Development',
          title: 'System Integration', 
          description: 'We offer API development and API integration services spanning a wide range of industries. We specialize in developing and integrating APIs for the Web, mobile applications and cloud. Our APIs integrate smoothly with other apps and providing you the flexibility to expand your service through multiple platforms such as mobile devices and offline software.',
          imgUrl: 'img/services/Group 2107.jpg',
          link: '/intergration',          
        },
        {
          type: 'Software Development',
          title: 'Ai, Big Data & Machine Learning', 
          description: 'We believe in delivering top-rated performance to our clients by offering superior AI & Machine Learning Solutions which precisely meet their requirements and maximize their ROI by automating their business processes.',
          imgUrl: 'img/services/Group 2108.jpg',
          link: '/AI',          
        },
        {
          type: 'Software Development',
          title: 'Enterprise System Develoment', 
          description: 'We have over 10 years of experience in the development of customized software, business applications, and enterprise solutions for diverse business needs. We provide a wide range of ERP solutions for global enterprises.  Our software is designed to rapidly adapt to your business processes, thereby maximizing enterprise agility and profits.',
          imgUrl: 'img/services/Group 2109.jpg',
          link: '/enterprise',          
        },
        {
          type: 'Software Development',
          title: 'Software Reenginering', 
          description: 'With time, the legacy applications that once gave your business an edge slowly become disparate and outdated. Your powerful digital assets, however beneficial they might have been, rely on complicated architectures and bring in huge maintenance costs and the risk of being associated with outdated or unsupported hardware and software, leading to reduced business efficiency and that is the biggest reason why they should be replaced immediately.',
          imgUrl: 'img/services/Group 2111.jpg',
          link: '/re-engineering',          
        },    



       // Cyber Security
       {
          type: 'Cyber Security',
          title: 'Penetration Testing', 
          description: 'We conduct both internal and external penetration testing. To detect hidden systems flaws during penetration testing, we act like external attackers, trying to bypass protection measures and break into a company’s network. By doing this, we can evaluate the potential impact in the case that these flaws are exploited by actual attackers',
          imgUrl: 'img/services/Group 2099.jpg',
          link: '/penetration',          
        }, 
          {
          type: 'Cyber Security',
          title: 'Vulnerabilities Assessment', 
          description: 'With thousands of known vulnerabilities in your systems and countless unknown risks; defensive technologies such as anti-virus and firewalls alone are not enough to stop these attacks. Moreover, as you frequently make changes to networked computing environments in response to customer needs, you are inadvertently weakening your security posture by providing cyber criminals with new ways in.',
          imgUrl: 'img/services/Group 2113.jpg',
          link: '/vulnerability',          
         },
        {
          type: 'Cyber Security',
          title: 'Computer Forensics', 
          description: 'Our track record combined with the latest forensic tools and technical expertise make Magilatech a leader in data forensics, security and investigations.Our portable lab forensic systems are designed for portability and power when you need work done at another location. Our investment in high speed processing...',
          imgUrl: 'img/services/Group 2096.jpg',
          link: '/computer-forensics',          
        }, 
        {
          type: 'Cyber Security',
          title: 'Internet & Mobile Banking Security', 
          description: 'Today, businesses worldwide face the challenge of establishing their web presence, not only in presenting company information but as the frontline interface between a business and its customers, as well as a business and its employees. Our web application security experts employ a combination of automated tests using the latest tools and technology along with manual testing and examination. We examine web applications used externally and internally and underlying databases (oracle and mssql) for any misconfigurations, vulnerabilities and test for web attacks.',
          imgUrl: 'img/services/Group 2097.jpg',
          link: '/banking',          
        }, 
        {
          type: 'Cyber Security',
          title: 'Annual Maintainance Support', 
          description: 'A common perception of maintenance is that it merely involves fixing defects. However, one study indicated that over 80% of maintenance effort is used for non-corrective actions. This perception is perpetuated by users submitting problem reports that in reality are functionality enhancements to the system. More recent studies put the bug-fixing proportion closer to 21%.',
          imgUrl: 'img/services/Group 2098.jpg',
          link: '/annual',          
        }, 
        
       
        {
          type: 'Cyber Security',
          title: 'Fraud Investigation', 
          description: 'Magilatech team of Forensic and Certified Fraud Examiners conduct investigations on Computer fraud, employee embezzlement, kickback schemes and financial statement fraud. We use variety of tools and resources to uncover and correct illegal activities that can undermine the profitability of your business. We work with many types of organizations including public and private entities, nonprofit organizations, governmental entities and a variety of industries. Our practice includes work with attorneys in both civil and criminal lawsuits.',
          imgUrl: 'img/services/Group 2100.jpg',
          link: '/fraud',          
        },
        
        {
          type: 'Cyber Security',
          title: 'Incidents Management', 
          description: 'We have set of procedures and actions taken to respond to and resolve critical incidents, as we identify, analyze, and correct hazards to prevent a future re-occurrence. Therefore the process of limiting the potential disruption caused by such an event, followed by a return to business as usual is what we do as we manage Incidents in Magilatech.',
          imgUrl: 'img/services/Group 2114.jpg',
          link: '/Incidents',          
        } ,    
        {
          type: 'Cyber Security',
          title: 'Mobile Phone Forensics', 
          description: 'Magilatech Mobile Forensic team are able to extract key evidence from cellular phones, Smartphones, PDA’s, GPS units and other mobile devices. Using specialized software and equipment designed specifically for advanced cell phone forensics, We are able to extract active and deleted data from many...',
          imgUrl: 'img/services/Group 2101.jpg',
          link: '/mobile-phone',          
        },
        {
          type: 'Cyber Security',
          title: 'IT Governance', 
          description: 'IT governance is a critical component of corporate governance, it provides a useful tool for benchmarking the balance and effectiveness of IT governance practices within an organization. It also provides structured guidance on how to approach practical assistance and guidance for practitioners in an Organization.',
          imgUrl: 'img/services/Group 2102.jpg',
          link: '/governance',          
        }, 
        {
          type: 'Cyber Security',
          title: 'System Auditing', 
          description: 'Magilatech is a leading IT audit service provider and can help you address a wide range of IT audit requirements. Our IT audit team is highly experienced and has a thorough understanding of all the IT audit best practices. We can perform a thorough check of your systems to ensure that they are performing optimally and that you are getting the most from your investment and your IT systems.',
          imgUrl: 'img/services/Group 2103.jpg',
          link: '/auditing',          
        }

       
                                                                       
      ]
    
    return data
  }      


export default {
  services
}